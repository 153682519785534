import React from 'react';
import { Box, Container, useTheme, useMediaQuery,Typography, Link } from '@mui/material';
import { keyframes } from '@mui/system';



const Footer = () => {
  return (
<Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.05)', marginTop: 3}}>
    <Container maxWidth="md" > 
        <Typography variant="body2" align="center" color="textSecondary" sx={{ color: 'white', paddingTop: 3, paddingBottom: 1 }}>
            Наше казино © {new Date().getFullYear()} — это честная игра, быстрые выплаты и надежная защита Ваших данных. Играйте ответственно.
        </Typography>
        <Typography variant="body2" align="center" color="textSecondary" sx={{ color: 'white', paddingBottom: 3 }}>
            🎰 Для удобства используйте наше мини-приложение в <Link href={`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT}`} target="_blank" sx={{ color: '#00BFFF', textDecoration: 'none' }}>Telegram</Link> — быстрый доступ к играм и балансу!
        </Typography>
    </Container>
</Box>
  );
};

export default Footer;