import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const authAPI = {
    login: (auth_code) => api.post('/auth/login', { auth_code }),
    checkToken: () => api.get('/auth/check'),
    checkTgAuth: (TelegramAuthToken) => api.post('/auth/checkTg', { TelegramAuthToken }),
    loginWebApp: (validate) => api.post('/auth/tg_auth', validate),
    logout: () => api.post('/auth/logout'),
};

export const slotAPI = {
    getSlots: () => api.get('/slots'),
    getSlot: (id) => api.get(`/slots/${id}`),
    playSlot: (slotId, betAmount, session_id) => api.post(`/slots/${slotId}/spin`, { bet: betAmount, slotId: slotId, session_id: session_id }),
    getSlotHistory: (data) => api.post('/slots/history', data),
};

export const adminAPI = {
    getUsers: (params) => api.get('/admin/users', { params }),
    updateUser: (id, data) => api.put(`/admin/users/${id}`, data),
    deleteUser: (id) => api.delete(`/admin/users/${id}`),
    verifyUser: async (userId) => {
        return adminAPI.updateUser(userId, { isVerified: true });
    },
    createRandomUser: (params) => api.get('/admin/users/random'),
};

export const userAPI = {
    getProfileMe: () => api.get('/users/me'),
    getMyBalance: (refer) => api.get('/users/balance?refer=' + refer),
    verifyPhone: (phone) => api.post('/users/verify-phone', { phone }),
    confirmPhoneCode: (code) => api.post('/users/verify-phone-code', { code }),
    verifyEmail: (email) => api.post('/users/verify-email', { email }),
    confirmEmailCode: (code) => api.post('/users/verify-email-code', { code }),
    startVerification: () => api.post('/users/verify-profile'),
    updateProfile: (data) => api.put('/users/me', data),
};

export const cryptoAPI = {
    getCurrency: () => api.get('/crypto/currency'),
};

export const transactionApi = {
    createFiatDeposit: (data) => api.post('/transactions/deposit', data),
};

export const withdrawalApi = {
    create: (data) => api.post('/withdrawals', data),
    getMy: () => api.get('/withdrawals/my'),
    getAll: () => api.get('/withdrawals'),
    process: (id, data) => api.put(`/withdrawals/${id}/process`, data),
    delete: (id) => api.delete(`/withdrawals/${id}`),
};

export default api;
