import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AdbIcon from '@mui/icons-material/Adb';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../context/AuthContext';
import Balance from '../components/Balance';
import { useModal } from '../../context/ModalContext'; // Используем хук для доступа к контексту
import "../style/menu.css"
import "../style/layout.css"
const pages = ['Главная','/','Бонусы', '/bonuses', 'Акции', '/promotions', "ТЕХПОДДЕРЖКА", 'https://t.me/casiclovesup'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];


const Nav = ()  => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const { handleOpen, handleClose } = useModal();
    const { isAuthenticated, userData, logout } = useAuth();

    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        console.log(newOpen);
      setOpen(newOpen);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
  const profOpen = Boolean(anchorEl);

const profileOpen = (event) => {
  setAnchorEl(event.currentTarget);
};
const profileClose = () => {
  setAnchorEl(null);
 
};
const ProfileMenu = ({ anchorEl, profileClose }) => {
    const open = Boolean(anchorEl);
  
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={profileClose}
        onClick={(e) => e.stopPropagation()}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >

        <MenuItem onClick={() => {navigate('/profile'); setAnchorEl(null);}}>
          <ListItemIcon>
            <AccountBoxIcon fontSize="smail" />
          </ListItemIcon>
         Профиль
        </MenuItem>
        <MenuItem onClick={() => {navigate('/slots/history');   setAnchorEl(null);}}>
          <ListItemIcon>
            <HistoryIcon fontSize="small" />
          </ListItemIcon>
          История игр
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
        Выход
        </MenuItem>
      </Menu>
    );
  };

    const navigate = useNavigate();
    const DrawerList = (
        <Box sx={{ width: 250, height: "100%", paddingTop: "40px" }} className="menuBackGround"  role="presentation" onClick={toggleDrawer(false)}>
       <List>
  {pages.map((text, index) => (
    <ListItem 
      sx={{ color: "white", '&:hover': { backgroundColor: "var(--hover-color)" } }} 
      key={text} 
      disablePadding
    >
      {index % 2 === 0 && pages[index + 1] && ( // Проверяем, что есть pages[index + 1]
        <ListItemButton
          onClick={() => {
            const link = pages[index + 1]; // Берём путь из следующего элемента массива

            if (typeof link === 'string' && link.startsWith('https://')) { 
              console.log('linck')
              window.open(link, '_blank'); // Если это ссылка — открываем в новой вкладке
            } else {
              console.log('route')
              navigate(link); // Иначе это роут — используем navigate
            }
          }}
        >
          <ListItemText primary={text} />
        </ListItemButton>
      )}
    </ListItem>
  ))}
</List>
          <Divider />
          
        </Box>
      );
    
    return (
      
      <AppBar position="static" className="menuBackGround" >
        <Container sx={{ paddingTop: { xs: '40px', md: 0 } }} maxWidth="xl" className="menuBackGround" >
          <Toolbar disableGutters sx={{ height: 10 }} >
          <Box
  component="img"
  src="/chip-logo.png"
  alt="CasicLove"
  sx={{
    display: { xs: "none", md: "flex" },
    width: 40,
    height: 40,
    mr: 1
  }}
/>            {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1}} /> */}
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
          
            </Typography>
  
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
            </Box>
            <Box
  component="img"
  src="/chip-logo.png"
  alt="CasicLove"
  sx={{
    display:  { xs: 'flex', md: 'none' },
    width: 30,
    height: 30,
  }}
/> 
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
         
            </Typography>
            <Box sx={{ height: "100%", flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page, index) => (
                index % 2 === 0 && (
                <Button
                  key={page}
                  onClick={() => {
                    const link = pages[index + 1]; // Берём путь из следующего элемента массива
        
                    if (typeof link === 'string' && link.startsWith('https://')) { 
                      window.open(link, '_blank'); // Если это ссылка — открываем в новой вкладке
                    } else {
                      navigate(link); // Иначе это роут — используем navigate
                    }
                  }}
                  sx={{ my: 2, mt: 0, mb: 0, color: 'white',  display: 'block', '&:hover': {backgroundColor: "var(--hover-color)"}, borderRadius: "0px"}}
                >
                  {page}
                </Button>)
              ))}
            </Box>
            {isAuthenticated && ( <Balance/> )}
            <Box sx={{ flexGrow: 0 }}>
              
            {!isAuthenticated && (
              <Button 
    variant="outlined" 
    className="heartbeat-button"
    sx={{
        color: "var(--button-color)", 
        borderColor: "var(--button-color)", 
        '&:hover': {
            borderColor: "var(--button-hover-color)", 
            color: "var(--button-hover-color)"
        },
        '&.heartbeat-button': {
            animation: 'heartbeat 1.5s ease-in-out infinite',
            '&:hover': {
                animation: 'none'  // останавливаем анимацию при наведении
            }
        }
    }} 
    onClick={handleOpen}
>
    Вход / Регистрация
</Button>            )}

            {isAuthenticated && (
            
              <Box>
                <IconButton onClick={profileOpen} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              
                </IconButton>
                <ProfileMenu anchorEl={anchorEl} profileClose={profileClose}/>
              </Box>
            )}
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography sx={{ textAlign: 'center' }}>{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }

export default Nav