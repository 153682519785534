import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { authAPI } from "../Api";
import Loader from '../layouts/components/Loader';
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [userData, setUserData] = useState(JSON.parse(localStorage.getItem('userData')) || null);
    const [webApp, setWebApp] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [telegramWebApp, setTelegramWebApp] = useState(null);
    const [loadingMessage, setLoadingMessage] = useState('Выполняется вход в систему ...');
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const isFromTelegram = urlParams.has("miniApp");
        if(isFromTelegram) {
            localStorage.removeItem('token');
            localStorage.removeItem('userData');
            setToken(null);
            setUserData(null);
            setIsAuthenticated(false);
            setTelegramWebApp(true)
             // Показываем лоадер перед загрузкой скрипта
            const script = document.createElement("script");
            script.src = "https://telegram.org/js/telegram-web-app.js?56";
            script.async = true;
            script.onload = () => {
                if (window.Telegram && window.Telegram.WebApp) {
                    window.Telegram.WebApp.expand();
                    // console.log('Telegram WebApp in Auth:', window.Telegram.WebApp);
                    setWebApp(window.Telegram.WebApp);
                    window.Telegram.WebApp.setBackgroundColor("#ffffff");
                    window.Telegram.WebApp.setHeaderColor('#17181e');
                    const initData = window.Telegram.WebApp.initData;
                    window.Telegram.WebApp.expand();
                    window.Telegram.WebApp.requestFullscreen();
                    window.Telegram.WebApp.disableVerticalSwipes();
            
                    const header = document.querySelector('header');
                    header.style.paddingTop = 'var(--tg-content-safe-area-inset-top)';
                    let paddingTop = parseInt(header.style.paddingTop) + 30;
                    header.style.paddingTop = paddingTop + 'px';
                        setLoadingMessage(`Выполняется вход в систему ...`);
                         setIsLoading(true);
                        authAPI.loginWebApp({validate: initData})
                            .then((response) => {
                                if(response.data.user) {
                                    console.log('AUTH DATA', response.data.user)
                                    login(response.data.token, response.data.user);
                                     setIsLoading(false);
                                }
                            });
                }
                // setIsLoading(false); // Скрываем лоадер после загрузки
            };
            document.body.appendChild(script);
        }
    }, []);
    useEffect(() => {
        if (userData) {
            localStorage.setItem('userData', JSON.stringify(userData));
            console.log('СРАБОТАЛО УСЕРДАТА')
        }
    }, [userData]); // Следит за изменениями userData
    useEffect(() => {
        const validateToken = async () => {
            const storedToken = localStorage.getItem('token');
            if (!storedToken) {
                setIsAuthenticated(false);
                return;
            }
    
            try {
                await authAPI.checkToken();
                setIsAuthenticated(true);
            } catch (error) {
                console.error('Token validation failed:', error);
                localStorage.removeItem('token');
                localStorage.removeItem('userData');
                setToken(null);
                setUserData(null);
                setIsAuthenticated(false);
                // Дополнительно можно показать уведомление для пользователя
            }
        };
    
        validateToken();
    }, []);

    const login = async (tokenData, user) => {
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        setToken(null);
        setUserData(null);
        setIsAuthenticated(false);
        if (!tokenData) {
            console.error('Token is empty or invalid');
            return;
        }
        localStorage.setItem('token', tokenData);
        localStorage.setItem('userData', JSON.stringify(user));

        setToken(tokenData);
        setUserData(user);
        setIsAuthenticated(true);
    };
    const updateUserData = (newData) => {
        localStorage.removeItem('userData');
        localStorage.setItem('userData', JSON.stringify(newData));
        setUserData(newData);
    };
    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
        setToken(null);
        setUserData(null);
        setIsAuthenticated(false);
        navigate('/')
        if(telegramWebApp) {
            window.Telegram.WebApp.close();
        }
    };

    return (
        <AuthContext.Provider value={{ 
            isAuthenticated, 
            token, 
            userData,
            updateUserData,
            login, 
            logout,
            webApp,
            setIsLoading // Экспортируем функцию для управления лоадером
        }}>
            {isLoading && <Loader message={loadingMessage} />}
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    console.log(context)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};