import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Menu, 
  MenuItem, 
  ListItemIcon,
  Typography,
  Snackbar,
  Alert
} from '@mui/material';
import { DepositDialog, WithdrawDialog } from './BalanceModals';
import { userAPI } from '../../Api';
import { useAuth,setUserData } from '../../context/AuthContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import HistoryIcon from '@mui/icons-material/History';
import AddCardIcon from '@mui/icons-material/AddCard';

const Balance = () => {
  const [balance, setBalance] = React.useState(0);
  const [intervalId, setIntervalId] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDepositDialog, setOpenDepositDialog] = React.useState(false);
  const [openWithdrawDialog, setOpenWithdrawDialog] = React.useState(false);
  const [amount, setAmount] = React.useState('');
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
  const location = useLocation();
  const { isAuthenticated, userData, logout, updateUserData } = useAuth();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  useEffect(() => {
    let interval;

    if (!isAuthenticated) return;
    console.log('TOKEN JIEST',localStorage.getItem('token'))
    const fetchBalance = async () => {
      try {
        console.log(location)
        const response = await userAPI.getMyBalance(location.pathname);
        setBalance(response.data.balance); //
        updateUserData({
          ...userData,
          user: {
            ...userData.user,
            balance: response.data.balance
          }
        })
      } catch (error) {
        console.error('Ошибка при получении баланса:', error);
        navigate('/');
        logout();
      }
    };

    fetchBalance();
    interval = setInterval(fetchBalance, 30000);

    return () => clearInterval(interval);
  }, [isAuthenticated]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleDeposit = () => {
    showSnackbar('Заявка на пополнение принята', 'success');
    setOpenDepositDialog(false);
    setAmount('');
  };

  const handleWithdraw = () => {
    const withdrawAmount = parseFloat(amount);
    if (withdrawAmount > balance) {
      showSnackbar('Сумма вывода не может превышать текущий баланс', 'error');
      return;
    }
    showSnackbar('Заявка принята, в течение 24 часов средства поступят на ваш счет', 'success');
    setOpenWithdrawDialog(false);
    setAmount('');
  };

  const DepositMenu = ({ anchorEl, handleClose }) => {
    const open = Boolean(anchorEl);
  
    return (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => {
          handleClose();
          setOpenDepositDialog(true);
        }}>
          <ListItemIcon>
            <AddCardIcon fontSize="small" />
          </ListItemIcon>
          Пополнить счет
        </MenuItem>
        <MenuItem onClick={() => {
          handleClose();
          setOpenWithdrawDialog(true);
        }}>
          <ListItemIcon>
            <PriceCheckIcon fontSize="small" />
          </ListItemIcon>
          Вывод средств
        </MenuItem>
        <MenuItem 
          onClick={() => {navigate('/transaction/history'); handleClose(); }}
          sx={{ 
            opacity: 0.5,
            pointerEvents: 'none',
            cursor: 'not-allowed'
          }}>
          <ListItemIcon>
            <HistoryIcon fontSize="small" />
          </ListItemIcon>
          История транзакций
        </MenuItem>
      </Menu>
    );
  };

  const moneyFormat = (amount) => {
    if (isNaN(amount)) {
      throw new Error("Invalid amount");
    }
    const formattedBalance = new Intl.NumberFormat('ru-RU', {
      style: 'currency',
      currency: 'RUB',
      maximumFractionDigits: 2
    }).format(amount);
    return formattedBalance;
  };

  return (
    <>
      <Box 
        mr={2}
        display={'flex'}
        alignItems={'center'}
        onClick={handleClick}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          backgroundColor: 'var(--hover-color)', 
          padding: '4px 8px', 
          color: 'var(--button-color)', 
          borderRadius: '4px', 
          cursor: 'pointer'
        }}
      >
        {moneyFormat(balance)}
        <ArrowDropDownIcon />
        <DepositMenu anchorEl={anchorEl} handleClose={handleClose} />
      </Box>

      <DepositDialog
        open={openDepositDialog}
        onClose={() => setOpenDepositDialog(false)}
        amount={amount}
        setAmount={setAmount}
        onDeposit={handleDeposit}
      />

      <WithdrawDialog
        open={openWithdrawDialog}
        onClose={() => setOpenWithdrawDialog(false)}
        amount={amount}
        setAmount={setAmount}
        onWithdraw={handleWithdraw}
        balance={balance}
        moneyFormat={moneyFormat}
      />

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Balance;