import React, { useEffect, useRef, forwardRef, useCallback, useState } from 'react';
import * as PIXI from 'pixi.js';
import  BettingPanel  from '../component/BettingPanel';
import { DepositDialog } from '../../layouts/components/BalanceModals';
import { slotAPI } from '../../Api';
import { gsap } from 'gsap';
import {useAuth} from '../../context/AuthContext';
import { Box } from '@mui/material';
import SlotLoader from '../component/SlotLoading';
const BASE_SYMBOL_SIZE = 120;
const REEL_WIDTH = BASE_SYMBOL_SIZE;

const getProxyUrl = (url) => {
  if (!url) return null;
  if (!/https?:\/\//.test(url)) {
    return `${url}`;
  }
  return url.replace(`${process.env.REACT_APP_STATIC_URL}/`, '/');
};


const SlotMachines = forwardRef(({slotData, user }, ref) => {
  const appRef = useRef(null);
  const texturesContainerRef = useRef(null);
  const overlayContainerRef = useRef(null);
  const backgroundRef = useRef(null);
  const containerRef = useRef(null);
  const mountedRef = useRef(true);
  const borderRef = useRef(null);
  const reelContainerRef = useRef(null);
  const totalWinTextRef = useRef(null);
  const totalWinContainerRef  = useRef(null);
  const winTextRef = useRef(null); // Добавляем ref для текста выигрыша
  const runningRef = useRef(false);
  const tweening = useRef([]);
  const texturesRef = useRef({});

  const { userData, updateUserData } = useAuth();
  const [loaderOpen, setLoaderOpen] = useState(false);
  const [isMobileMod, setIsMobileMod] = useState(document.documentElement.clientWidth <= 430);
  const [balance, setBalance] = useState(user.balance);
  const [windowSize, setWindowSize] = useState({
    width: (document.documentElement.clientWidth <= 430) ? 430 : 800,
    height: (document.documentElement.clientWidth <= 430) ? 600 : 600 ,
});
  const [depositOpen, setDepositOpen] = useState(false);
  const [depositAmount, setDepositAmount] = useState('');
  useEffect(() => {
    if(reelContainerRef.current && reelContainerRef.current.success) {
      console.log('reelContainerRef', reelContainerRef)
    }
  }, [reelContainerRef.current])
  useEffect(() => {
  setIsMobileMod(windowSize.width <= 430);
 }, [windowSize]);
const { width, height } = windowSize;
  // Определяем handleEvent в начале компонента
  const handleEvent = useCallback((eventType, bet) => {
    if (!mountedRef.current) return;

    if (eventType === 'updateBet') {
      // Сохраняем текущую ставку
      console.log('Current bet updated:', bet);
      return;
    }

    if (eventType === 'spin') {
      PlaySlot(bet, texturesRef.current);
    }

    const containers = [texturesContainerRef.current, overlayContainerRef.current];
    
    containers.forEach(container => {
      if (!container) {
        console.warn('Container is null:', container);
        return;
      }

      container.children.forEach(sprite => {
        if (!sprite || !sprite.textureData) {

          return;
        }

        const { animation } = sprite.textureData;
        if (!animation) {

          return;
        }

        // Проверяем, должна ли анимация воспроизводиться по этому событию
        if (animation.playOnEvent === eventType) {

          // Проверяем, что это анимированный спрайт и у него есть текстуры
          if (sprite instanceof PIXI.AnimatedSprite && sprite.textures?.length > 0) {
            
            // Сначала останавливаем текущую анимацию, если она есть
            sprite.stop();
            
            // Устанавливаем скорость анимации и запускаем
            sprite.animationSpeed = animation.speed || 0.5;
            sprite.loop = true;
            sprite.gotoAndPlay(0);

            // Если указана длительность, останавливаем анимацию после неё
            if (animation.duration > 0) {
              setTimeout(() => {
                console.log('Animation duration expired for sprite:', sprite.id);
                if (sprite && !sprite.destroyed) {
                  sprite.stop();
                  sprite.gotoAndStop(0);
                }
              }, animation.duration * 1000);
            }
          } else {
            console.warn('Sprite is not animated or has no textures:', {
              id: sprite.id,
              isAnimated: sprite instanceof PIXI.AnimatedSprite,
              hasTextures: sprite.textures?.length > 0
            });
          }
        }
      });
    });
  }, []);
  
  const PlaySlot = async (bet, textures) => {
    if (!reelContainerRef.current || runningRef.current) return;

    runningRef.current = true;
    if (winTextRef.current && reelContainerRef.current.children.includes(winTextRef.current)) {
      reelContainerRef.current.removeChild(winTextRef.current);
      winTextRef.current = null;
    }
    if (totalWinTextRef.current) {
      totalWinTextRef.current.text = '';
      totalWinContainerRef.current.alpha = 0;
      totalWinTextRef.current.scale.set(1);
    }
    
    // Находим контейнер с барабанами
    const reelsContainer = reelContainerRef.current.children[1].children[1];
    const reels = reelsContainer.children;
console.log('reels:', reels);
    let completedReels = 0;
    const spin = await slotAPI.playSlot(slotData.id,bet,user.session_id);
    if(spin.data.error) {
      console.log('Ошибка')
      return ;
    }
    console.log('Spin response:', spin);
    setBalance(spin.data.balanceOld); 
    for (let i = 0; i < reels.length; i++) {
      const reel = reels[i];
      const delay = i * 0.2;
      clearGsap(reel.children.filter(child => child instanceof PIXI.Sprite));

      // Получаем размеры
      const symbolHeight = BASE_SYMBOL_SIZE + slotData.visual.symbolsAreaSettings.symbolScale;
      const visibleHeight = symbolHeight * slotData.visual.reels.numberOfRows;
      console.log('Символы:', symbolHeight, 'Видимая область:', visibleHeight);
      // Создаем анимацию
      gsap.timeline()
        .to(reel, {
          y: "-=50",
          duration: 0.01,
          delay: delay,
          ease: "power2.in"
        })
        .to(reel, {
          y: `+=${visibleHeight * 5}`, // Прокручиваем на 4 полных оборота
          duration: 0.5,
          ease: "power2.out",
    
          onUpdate: () => {
            
            const symbols = reel.children.filter(child => child instanceof PIXI.Sprite);
           
            const reelGlobalY = reel.y;
            
            symbols.forEach((symbol) => {
              const symbolGlobalY = symbol.y + reelGlobalY;
              // Если символ ушел выше видимой области
              if (symbolGlobalY < -symbolHeight) {
                // Перемещаем его в конец
                const lastSymbol = symbols[symbols.length - 1];
                symbol.y = lastSymbol.y + symbolHeight;
                
                // Меняем текстуру на случайную
                
                const randomSymbol = slotData.symbols[Math.floor(Math.random() * slotData.symbols.length)];
                if (randomSymbol && textures[randomSymbol.id]) {
                  symbol.texture = textures[randomSymbol.id];
                }
              }
            });
          }
        })
        .to(reel, {
          y: (isMobileMod) ? 25 : 0,
          duration: 0.1,
          ease: "bounce.out",
  
          onComplete: () => {
            completedReels++;    
            let reelIndex = completedReels - 1;
            const reel = reels[reelIndex];
            const symbols = reel.children.filter(child => child instanceof PIXI.Sprite);
            for (let rowIndex = 0; rowIndex < slotData.visual.reels.numberOfRows; rowIndex++) {
              const symbolId = spin.data.spinResult.matrix[rowIndex][reelIndex];
              const symbol = symbols[rowIndex];
              if (symbol && textures[symbolId]) {
                symbol.texture = textures[symbolId];
                symbol.reel = reelIndex;
                symbol.row = rowIndex;
    
              }
            }
  
  
            if (completedReels === reels.length) {
              runningRef.current = false;
              if (spin.data.spinResult.winningLines && spin.data.spinResult.winningLines.length > 0 && !runningRef.current) {
                   // Обновляем totalWin
        if (totalWinTextRef.current) {
          totalWinContainerRef.current.alpha = 1;
          totalWinTextRef.current.text = `${spin.data.spinResult.totalWin} ₽`;
          
          // Анимируем текст при выигрыше
          if (spin.data.spinResult.totalWin > 0) {
            const countObj = { val: 0 };
                  
            gsap.to(countObj, {
              val: spin.data.spinResult.totalWin,
              duration: 2, // Длительность анимации
              ease: "power1.inOut",
              onUpdate: function() {
                totalWinTextRef.current.text = `${Math.floor(countObj.val)} ₽`;
                totalWinContainerRef.current.alpha = 1;
              },
              onComplete: function() {
                // После завершения накрутки делаем пульсацию
                setBalance(spin.data.balance)
                updateUserData({
                  ...userData,
                  user: {
                    ...userData.user,
                    balance: spin.data.balance
                  }
                })
                gsap.fromTo(totalWinTextRef.current.scale,
                  { x: 1, y: 1 },
                  {
                    x: 1.2,
                    y: 1.2,
                    duration: 0.3,
                    yoyo: true,
                    repeat: 3,
                    ease: "power2.out"
                  }
                );
              }
            });
          }
        }
                const animateLine = (lineIndex) => {
                  // Сначала сбрасываем все символы на полупрозрачность
                  reels.forEach(reel => {
                    const symbols = reel.children.filter(child => child instanceof PIXI.Sprite);
                    symbols.forEach(symbol => {
                      gsap.killTweensOf(symbol);
                  
                    });
                  });
            
                  const line = spin.data.spinResult.winningLines[lineIndex];
                  const winText = new PIXI.Text(`WIN: ${line.payout} ₽`, {
                    fontFamily: 'Arial',
                    fontSize: 36,
                    fill: 0xFFD700,
                    stroke: 0x000000,
                    strokeThickness: 4
                  });
                  winTextRef.current = winText; // Сохраняем ссылку на текст

// Позиционируем текст

winText.x = containerRef.current.x + containerRef.current.width / 2;
winText.y = containerRef.current.y + containerRef.current.height / 2;
reelContainerRef.current.addChild(winText);

// Анимируем появление текста
gsap.to(winText, {
  alpha: 1,
  y: winText.y - 30,
  duration: 0.5
});
                  // Анимируем текущую линию
                  line.positions.forEach(pos => {
                    const reel = reels[pos.reel];
                    if (reel) {
                      const symbols = reel.children.filter(child => 
                        child instanceof PIXI.Sprite &&
                        typeof child.row === 'number' && 
                        child.row === pos.row
                      );
                      
                      if (symbols.length > 0) {
                        const symbol = symbols[0];
                        symbol.alpha = 1;
                        gsap.to(symbol, {
                          alpha: 0.3,
                          duration: 0.5,
                          repeat: 3, // Повторяем анимацию 3 раза
                          yoyo: true,
                          ease: "power1.inOut",

                          onComplete: () => {
                            // Когда анимация закончена, переходим к следующей линии
                      
                              // Удаляем текст
                              reelContainerRef.current.removeChild(winText);
  
                            
                            const nextLineIndex = (lineIndex + 1) % spin.data.spinResult.winningLines.length;
                            animateLine(nextLineIndex);
                          }
                          
                        });
                      }
                    }
                  });
                };
            
                // Начинаем с первой линии
                animateLine(0);
              }
            }
          }
        });
    }
  };
const clearGsap = (symbols) => {
  symbols.forEach(symbol => {
    symbol.alpha = 1;
    gsap.killTweensOf(symbol);
  });
}
  
  // Сбрасываем состояние при размонтировании
  useEffect(() => {
    return () => {
      runningRef.current = false;
    };
  }, []);

  // Add animation ticker
  useEffect(() => {
    if (!appRef.current) return;

    // Обработчик анимации
    const animate = () => {
      requestAnimationFrame(animate);
      
      if (!reelContainerRef.current) return;
      
      const reels = reelContainerRef.current.children[1].children[1].children;
      // Пропускаем первый элемент (border)
      for (let i = 0; i < reels.length; i++) {
        const reel = reels[i];
        
        // Обновляем позиции символов
        for (let j = 0; j < reel.children.length; j++) {
          const symbol = reel.children[j];
          const symbolHeight = BASE_SYMBOL_SIZE;
          const reelHeight = symbolHeight * slotData.visual.reels.numberOfRows;
          
          // Если символ ушел за пределы видимой области, перемещаем его наверх
          if (symbol.y + reel.y > reelHeight) {
            symbol.y -= reelHeight;
            
            // Меняем текстуру на случайную
            const randomSymbol = slotData.symbols[Math.floor(Math.random() * slotData.symbols.length)];
            if (randomSymbol && texturesRef.current[randomSymbol.id]) {
              symbol.texture = texturesRef.current[randomSymbol.id];
            }
          }
        }
      }
    };

    animate();
  }, []);

  // Basic lerp function
  function lerp(a1, a2, t) {
    return a1 * (1 - t) + a2 * t;
  }

  // Backout function from tweenjs
  function backout(amount) {
    return (t) => --t * t * ((amount + 1) * t + amount) + 1;
  }

  // Tweening utility function
  function tweenTo(object, property, target, time, easing, onchange, oncomplete) {
    const tween = {
      object,
      property,
      propertyBeginValue: object[property],
      target,
      easing,
      time,
      change: onchange,
      complete: oncomplete,
      start: Date.now(),
    };

    tweening.current.push(tween);
    return tween;
  }

  function backout(amount)
  {
      return (t) => --t * t * ((amount + 1) * t + amount) + 1;
  }
  // Теперь определяем spin после handleEvent
  const spin = useCallback((bet) => {
    handleEvent('spin', bet);
  }, [handleEvent]);

  // Экспортируем методы через ref
  React.useImperativeHandle(ref, () => ({
    spin
  }), [spin]);

  const calculateScale = (contentWidth, contentHeight, padding) => {
    const { width, height } = windowSize;
    const maxWidth = width - (padding * 2);
    const maxHeight = height - (padding * 2);
    
    const scaleX = maxWidth / contentWidth; 
    const scaleY = maxHeight / contentHeight;
    
    return Math.min(scaleX, scaleY);
  };

  const updateContainerLayout = () => {
    if (!mountedRef.current || !reelContainerRef.current || !borderRef.current) return;
    const { width, height } = windowSize;
    const {
      symbolSpacing = 0,
      areaPadding = 20,
      symbolScale = 1.0,
      areaPosition = { x: 50, y: 50 }
    } = (isMobileMod) ? slotData.visual.symbolsAreaSettingsMobile : slotData.visual.symbolsAreaSettings || {};

    // Рассчитываем размеры с учетом масштаба символов
    const scaledSymbolSize = BASE_SYMBOL_SIZE * symbolScale;

    // Рассчитываем общие размеры с учетом масштаба
    const contentWidth = (slotData.visual.reels.numberOfReels * scaledSymbolSize) + 
                        (symbolSpacing * (slotData.visual.reels.numberOfReels - 1)) + 
                        (areaPadding * 2);
    const contentHeight = (slotData.visual.reels.numberOfRows * scaledSymbolSize) + 
                         (symbolSpacing * (slotData.visual.reels.numberOfRows - 1)) + 
                         (areaPadding * 2);

    // Рассчитываем масштаб для вписывания в канвас
    const containerScale = calculateScale(contentWidth, contentHeight, 20);

    // Применяем масштаб к контейнеру
    reelContainerRef.current.scale.set(containerScale);

    // Рассчитываем позицию на основе процентов
    const xPos = (width - (contentWidth * containerScale)) * (areaPosition.x );
    const yPos = (height - (contentHeight * containerScale)) * (areaPosition.y );

    // Центрируем контейнер с учетом заданной позиции
    reelContainerRef.current.x = xPos;
    reelContainerRef.current.y = yPos;
  };

  const updateSymbolsAreaBorder = () => {
    if (!mountedRef.current || !borderRef.current || !slotData.visual.symbolsAreaSettings) return;

    const border = borderRef.current;
    border.clear();

    const {
      borderColor = '#FFD700',
      borderWidth = 4,
      borderOpacity = 1,
      borderRadius = 0,
      areaPadding = 20,
      areaWidth = 800,   // Используем заданную ширину
      areaHeight = 600,  // Используем заданную высоту
      areaPosition = {
        x: 50,
        y: 50
      },
      symbolSpacing = 0,
      symbolScale = 1.0,
      areaBackground = {
        color: '#000000',
        opacity: 0.5
      }
    } = (isMobileMod) ? slotData.visual.symbolsAreaSettingsMobile : slotData.visual.symbolsAreaSettings || {};

    // Рисуем фон
    const bgColor = parseInt(areaBackground.color.replace('#', '0x'));
    border.beginFill(bgColor, areaBackground.opacity);
    border.drawRoundedRect(
      -areaPadding,
      -areaPadding,
      areaWidth,  // Используем заданную ширину
      areaHeight, // Используем заданную высоту
      borderRadius
    );
    border.endFill();

    // Рисуем рамку
    const color = parseInt(borderColor.replace('#', '0x'));
    border.lineStyle({
      width: borderWidth,
      color: color,
      alpha: borderOpacity,
      alignment: 0.5,
      join: PIXI.LINE_JOIN.ROUND,
      cap: PIXI.LINE_CAP.ROUND
    });
    border.drawRoundedRect(
      -areaPadding,
      -areaPadding,
      areaWidth,  // Используем заданную ширину
      areaHeight, // Используем заданную высоту
      borderRadius
    );

    updateContainerLayout();
  };

  
  const updateTextures = async (onTexturesLoaded) => {
    const texturesContainer = texturesContainerRef.current;
    const overlayContainer = overlayContainerRef.current;
    if (!texturesContainer || !overlayContainer) return;

    // Проверка наличия колбека
    if (typeof onTexturesLoaded !== 'function') {
        return;
    }

    // Создаем карту существующих спрайтов
    const existingSprites = new Map();
    [...texturesContainer.children, ...overlayContainer.children].forEach(sprite => {
        if (sprite.textureData) {
            existingSprites.set(sprite.textureData.id, {
                sprite,
                updated: false,
                container: sprite.parent === texturesContainer ? 'textures' : 'overlay'
            });
        }
    });

    // Счетчик загруженных текстур
    let loadedTexturesCount = 0;
    const totalTextures = (isMobileMod) ? slotData.visual.texturesMobile?.length || 0 : slotData.visual.textures?.length || 0;

    const checkAllTexturesLoaded = () => {
        if (loadedTexturesCount === totalTextures) {
            onTexturesLoaded(); // Вызываем колбек, когда все текстуры загружены
        }
    };

    if ((isMobileMod && slotData.visual.texturesMobile && slotData.visual.texturesMobile.length > 0) || (slotData.visual.textures && slotData.visual.textures.length > 0)) {
        // Сортируем текстуры по zIndex
        const sortedTextures = isMobileMod ? [...slotData.visual.texturesMobile].sort((a, b) => (a.zIndex || 0) - (b.zIndex || 0)) : [...slotData.visual.textures].sort((a, b) => (a.zIndex || 0) - (b.zIndex || 0));

        for (const textureData of sortedTextures) {
            try {
                const existing = existingSprites.get(textureData.id);
                let sprite = existing?.sprite;

                if (!sprite) {
                    // Создаем новый спрайт только если его еще нет
                    if (textureData.isAnimated && textureData.frames) {
                        // Загружаем все кадры анимации
                        const frameTextures = await Promise.all(
                            textureData.frames.map(async (frame) => {
                                const proxyUrl = getProxyUrl(frame.url);
                                const texture = PIXI.Texture.from(proxyUrl);

                                texture.baseTexture.on('loaded', () => {
                                    sprite.width = textureData.size?.width;
                                    sprite.height = textureData.size?.height;
                                    sprite.anchor.set(0.5); // Центрируем
                                });

                                return texture;
                            })
                        );

                        sprite = new PIXI.AnimatedSprite(frameTextures);

                        // Настроим анимацию
                        sprite.animationSpeed = textureData.animation?.speed || 0.5;

                        // Добавляем обработчики событий для анимации
                        if (!textureData.animation?.autoPlay) {
                            sprite.stop();

                            if (textureData.animation?.playOnEvent === 'hover') {
                                sprite.on('mouseover', () => {
                                    sprite.play();
                                    if (textureData.animation?.duration > 0) {
                                        setTimeout(() => sprite.stop(), textureData.animation.duration);
                                    }
                                });
                                sprite.on('mouseout', () => sprite.stop());
                            } else if (textureData.animation?.playOnEvent === 'click') {
                                sprite.on('click', () => {
                                    sprite.play();
                                    if (textureData.animation?.duration > 0) {
                                        setTimeout(() => sprite.stop(), textureData.animation.duration);
                                    }
                                });
                            }
                        } else {
                            sprite.play();
                            if (textureData.animation?.duration > 0) {
                                setTimeout(() => sprite.stop(), textureData.animation.duration);
                            }
                        }
                    } else {
                        const proxyUrl = getProxyUrl(textureData.url);
                        if (!proxyUrl) return;

                        const texture = await PIXI.Texture.from(proxyUrl);
                        sprite = new PIXI.Sprite(texture);
                    }
                    sprite.scale.set(textureData.scale?.x ? -1 : 1, textureData.scale?.y ? -1 : 1);
                    sprite.alpha = textureData.settings?.alpha || 1;
                    sprite.zIndex = parseInt(textureData.settings?.zIndex) || 0;
                    sprite.anchor.set(0.5);
                    sprite.interactive = true;
                    sprite.buttonMode = true;

                } else {
                    // Обновляем настройки анимации для существующего спрайта
                    if (sprite instanceof PIXI.AnimatedSprite) {
                        sprite.animationSpeed = textureData.animation?.speed || 0.5;

                        if (textureData.animation?.autoPlay) {
                            sprite.play();
                            if (textureData.animation?.duration > 0) {
                                setTimeout(() => sprite.stop(), textureData.animation.duration);
                            }
                        } else {
                            sprite.stop();
                        }
                    }
                }

                // Обновляем параметры спрайта
                if (textureData.size) {
                    sprite.width = textureData.size.width;
                    sprite.height = textureData.size.height;
                } else {
                    const defaultSize = 200;
                    sprite.width = defaultSize;
                    sprite.height = defaultSize;
                }

                sprite.position.x = (width * textureData.position.x) / 100;
                sprite.position.y = (height * textureData.position.y) / 100;
            
                sprite.zIndex = textureData.zIndex || 0;
                sprite.textureData = { ...textureData };

                // Определяем нужный контейнер
                const targetContainer = textureData.isOverlay ? overlayContainer : texturesContainer;
                const currentContainer = existing?.container === 'overlay' ? overlayContainer : texturesContainer;

                // Перемещаем спрайт в правильный контейнер, если нужно
                if (!existing || currentContainer !== targetContainer) {
                    if (existing) {
                        currentContainer.removeChild(sprite);
                    }
                    targetContainer.addChild(sprite);
                }

                // Помечаем спрайт как обновленный
                if (existing) {
                    existing.updated = true;
                }

                // Увеличиваем счетчик загруженных текстур
                loadedTexturesCount++;
                checkAllTexturesLoaded();
            } catch (error) {
                console.error('Error loading texture:', error);
            }
        }

        // Удаляем спрайты, которые больше не используются
        existingSprites.forEach(({ sprite, updated, container }, id) => {
            if (!updated) {
                const containerRef = container === 'overlay' ? overlayContainer : texturesContainer;
                containerRef.removeChild(sprite);
            }
        });
    } else {
        // Если текстур нет, очищаем контейнеры
        texturesContainer.removeChildren();
        overlayContainer.removeChildren();
    }
};

function tweenTo(object, property, target, time, easing, onchange, oncomplete)
{
    const tween = {
        object,
        property,
        propertyBeginValue: object[property],
        target,
        easing,
        time,
        change: onchange,
        complete: oncomplete,
        start: Date.now(),
    };

    tweening.current.push(tween);

    return tween;
}
useEffect(() => {
  updateTextures(() => {
      console.log('Все текстуры загружены!');
  });
}, []);
  

// useEffect(() => {
//   if(hasOwnProperty(slotData.visual, 'sounds') || slotData.visual.sounds) {
//   const sound = new Audio('/api' + slotData.visual.sounds.fon.url);
//   sound.loop = true; // Зацикливаем воспроизведение

//   const playSound = () => {
//     sound.play().catch(err => console.log('Ошибка воспроизведения:', err));
//     document.removeEventListener('click', playSound);
//   };

//   document.addEventListener('click', playSound);

//   return () => {
//     sound.pause();
//     sound.currentTime = 0;
//     document.removeEventListener('click', playSound);
//   };
// }
// }, []);
  useEffect(() => {
    if (slotData.onEvent) {
      slotData.onEvent((eventType) => {
        handleEvent(eventType);
      });
    }
  }, [slotData.onEvent, handleEvent]);

  useEffect(() => {
    mountedRef.current = true;

    if (!containerRef.current) return;
    const rendererType = PIXI.utils.isWebGLSupported() ? PIXI.RENDERER_TYPE.WEBGL : PIXI.RENDERER_TYPE.CANVAS;

    // Создаем приложение PIXI
    const app = new PIXI.Application({
      width,
      height,
      backgroundColor: 0x1099bb,
      renderer: rendererType,
      resolution: window.devicePixelRatio || 1,
      antialias: true


    });

    // Включаем сортировку по zIndex
    app.stage.sortableChildren = true;

    containerRef.current.appendChild(app.view);
    appRef.current = app;

    const loadBackground = async () => {
      // Проверяем, смонтирован ли компонент и существует ли приложение PixiJS
      if (!mountedRef.current || !appRef.current) return;
  
      // Удаляем существующий фон, если он есть
      if (backgroundRef.current) {
          appRef.current.stage.removeChild(backgroundRef.current);
          backgroundRef.current = null;
      }
  
      // Проверяем, задан ли путь к текстуре фона
      if(isMobileMod) {
        if (!slotData?.visual?.backgroundTextureMobile) {
          console.warn("Фоновая текстура отсутствует в данных.");
          return;
        }
      } else {
        if (!slotData?.visual?.backgroundTexture) {
          console.warn("Фоновая текстура отсутствует в данных.");
          return;
        }
      }

  
      try {
          const proxyUrl = getProxyUrl((isMobileMod) ? slotData.visual.backgroundTextureMobile : slotData.visual.backgroundTexture); // Получаем прокси-URL
          if (!proxyUrl) {
              console.warn("Некорректный URL для фоновой текстуры.");
              return;
          }
  
          // Загружаем текстуру асинхронно
          const texture = await new Promise((resolve, reject) => {
              const tempTexture = PIXI.Texture.from(proxyUrl, 
                { resolution: window.devicePixelRatio, 
                  scaleMode: PIXI.SCALE_MODES.LINEAR });
            
              if (tempTexture.valid) {
                  resolve(tempTexture);
              } else {
                  tempTexture.baseTexture.once("loaded", () => resolve(tempTexture));
                  tempTexture.baseTexture.once("error", (err) => reject(err));
              }
          });
  
          if (!mountedRef.current) return; // Прерываем загрузку, если компонент размонтирован
  
          // Создаем спрайт для фона
          const background = new PIXI.Sprite(texture);
          background.width = width;
          background.height = height;
    
          background.x = 0;
          background.y = 0;
  
          // Добавляем фон на сцену
          appRef.current.stage.addChildAt(background, 0);
          backgroundRef.current = background;
      } catch (error) {
          console.error("Ошибка загрузки фоновой текстуры:", error);
      }
  };
  

    const createFallbackTexture = () => {
      if (!mountedRef.current || !appRef.current) return null;
      const graphics = new PIXI.Graphics();
      graphics.beginFill(0xff0000);
      graphics.drawRect(0, 0, BASE_SYMBOL_SIZE, BASE_SYMBOL_SIZE);
      graphics.endFill();
      return appRef.current.renderer.generateTexture(graphics);
    };

    const createReels = async (textures) => {
      if (!mountedRef.current || !appRef.current) return;
      
      // Очищаем предыдущий контейнер если он существует
      if (reelContainerRef.current) {
        appRef.current.stage.removeChild(reelContainerRef.current);
      }
      
      const mainContainer = new PIXI.Container();
      mainContainer.sortableChildren  = true;
      reelContainerRef.current = mainContainer;
  // Создаем контейнер для totalWin
    
      // Создаем рамку
      const reelBorder = new PIXI.Graphics();
      borderRef.current = reelBorder;
      mainContainer.addChild(reelBorder);

      const {
        symbolSpacing = 0,
        symbolScale = 1.0,
        areaWidth = 800,
        areaHeight = 600,
        areaPadding = 20
      } = (isMobileMod) ? slotData.visual.symbolsAreaSettingsMobile : slotData.visual.symbolsAreaSettings || {}; 

      // Рассчитываем размеры с учетом рамки
      const availableWidth = areaWidth - (areaPadding * 2);
      const availableHeight = areaHeight - (areaPadding * 2);

      // Рассчитываем размер одного символа
      const symbolWidth = (availableWidth - (symbolSpacing * (slotData.visual.reels.numberOfReels - 1))) / slotData.visual.reels.numberOfReels;
      const symbolHeight = (availableHeight - (symbolSpacing * (slotData.visual.reels.numberOfRows - 1))) / slotData.visual.reels.numberOfRows;
      const symbolSize = Math.min(symbolWidth, symbolHeight);

      // Центрируем сетку символов внутри рамки
      const totalGridWidth = (symbolSize * slotData.visual.reels.numberOfReels) + (symbolSpacing * (slotData.visual.reels.numberOfReels - 1));
      const totalGridHeight = (symbolSize * slotData.visual.reels.numberOfRows) + (symbolSpacing * (slotData.visual.reels.numberOfRows - 1));
      const startX = (availableWidth - totalGridWidth) / 2;
      const startY = (availableHeight - totalGridHeight) / 2;

      // Создаем контейнер для символов (который будет маскирован)
      const symbolsContainer = new PIXI.Container();
      symbolsContainer.sortableChildren  = true;
      mainContainer.addChild(symbolsContainer);

      // Создаем маску для всей области символов
      const mask = new PIXI.Graphics();
      mask.beginFill(0xFFFFFF);
      mask.drawRect(
        startX - symbolSpacing/2,
        startY - symbolSpacing/2,
        totalGridWidth + symbolSpacing,
        totalGridHeight + symbolSpacing
      );
      mask.endFill();
      symbolsContainer.mask = mask;
      symbolsContainer.addChild(mask);
      
      // Создаем контейнер для барабанов
      const reelsContainer = new PIXI.Container();
      reelsContainer.sortableChildren  = true;
      symbolsContainer.addChild(reelsContainer);
      
      for (let i = 0; i < slotData.visual.reels.numberOfReels; i++) { 
        if (!mountedRef.current) break;

        const reel = new PIXI.Container();
        reel.sortableChildren  = true;
        const reelX = startX + (i * (symbolSize + symbolSpacing));
        reel.x = reelX;
        reel.y = startY;

        // Создаем маску для отдельного барабана
        const reelMask = new PIXI.Graphics();
        reelMask.beginFill(0xFFFFFF);
        reelMask.drawRect(
          0,
          0,
          symbolSize + symbolSpacing/2,
          totalGridHeight + symbolSpacing
        );
        reelMask.endFill();
        reel.mask = reelMask;
        reel.addChild(reelMask);

        reelsContainer.addChild(reel);

        // Добавляем дополнительные символы сверху и снизу для плавного перехода
        const extraSymbols = 2;
        const totalSymbols = slotData.visual.reels.numberOfRows ;
        
        for (let j = 0; j < totalSymbols; j++) {
          if (!mountedRef.current) break;

          const randomSymbol = slotData.symbols[Math.floor(Math.random() * slotData.symbols.length)];
          if (randomSymbol && textures[randomSymbol.id]) {
            const symbol = new PIXI.Sprite(textures[randomSymbol.id]);
            symbol.width = symbolSize;
            symbol.height = symbolSize;
            symbol.symbolId = randomSymbol.id;
            symbol.y = (j ) * (symbolSize + symbolSpacing);
            reel.addChild(symbol);
          }
        }
      }

      // Обновляем границу после создания всех элементов
      updateSymbolsAreaBorder();
      appRef.current.stage.addChild(mainContainer);
      const totalWinContainer = new PIXI.Container();
      totalWinContainer.sortableChildren  = true;
      totalWinContainerRef.current = totalWinContainer;

      // Создаем фон для totalWin
      const totalWinBg = new PIXI.Graphics();
      totalWinBg.beginFill(0x000000, 0.7);
      totalWinBg.drawRect(0, 0, app.screen.width /2, 60);
      totalWinBg.endFill();
      totalWinBg.x = (app.screen.width - totalWinBg.width) / 2;  // Горизонтальное центрирование
      totalWinBg.y = (app.screen.height - 60) / 2;  // Вертикальное центрирование (если хотите центрировать относительно экрана)
      totalWinContainer.addChild(totalWinBg);
      totalWinContainer.alpha = 0;
      // Создаем текст для totalWin
      const totalWinText = new PIXI.Text('', {
        fontFamily: 'Arial',
        fontSize: 32,
        fill: 0xFFD700,
        stroke: 0x000000,
        strokeThickness: 4,
        dropShadow: true,
        dropShadowColor: '#000000',
        dropShadowDistance: 2
      });
      totalWinText.anchor.set(0.5);
      totalWinText.x = app.screen.width / 2;
      totalWinText.y = app.screen.height / 2;
      totalWinContainer.addChild(totalWinText);
      totalWinTextRef.current = totalWinText;

      // Позиционируем контейнер
      totalWinContainer.y = 0;
      app.stage.addChild(totalWinContainer);
      console.log('Reels created:', reelContainerRef.current.children.length);
      reelContainerRef.current.children.forEach((child, i) => {
        console.log(`Reel ${i}:`, child);
      });
      reelContainerRef.current.success = true;
      setTimeout(() => {
        setLoaderOpen(true)
      }, 1000)
      
    
    };
 
  


    const loadTextures = async () => {
      // Проверяем, смонтирован ли компонент и существует ли приложение PixiJS
      if (!mountedRef.current || !appRef.current) return {};
  
      const textures = {}; // Объект для хранения загруженных текстур
      const fallbackTexture = createFallbackTexture(); // Создаем текстуру-заглушку
  
      if (!fallbackTexture) {
          console.error("Ошибка: не удалось создать текстуру-заглушку.");
          return {};
      }
  
      // Проверяем наличие символов в данных
      if (!slotData?.symbols) {
          console.warn("Отсутствуют символы в данных.");
          return {};
      }
  
      for (const symbol of slotData.symbols) {
          // Проверяем, не размонтирован ли компонент
          if (!mountedRef.current) break;
  
          // Если у символа есть изображение
          if (symbol.image) {
              try {
                  const proxyUrl = getProxyUrl(symbol.image); // Получаем прокси-URL
                  if (proxyUrl) {
                      // Загружаем текстуру и ждем её готовности
                      const texture = await new Promise((resolve, reject) => {
                          const tempTexture = PIXI.Texture.from(proxyUrl);
                          if (tempTexture.valid) {
                              resolve(tempTexture);
                          } else {
                              tempTexture.baseTexture.once("loaded", () => resolve(tempTexture));
                              tempTexture.baseTexture.once("error", (err) => reject(err));
                          }
                      });
  
                      if (!mountedRef.current) break; // Прерываем загрузку, если компонент размонтирован
  
                      textures[symbol.id] = texture;  // Сохраняем текстуру
                  }
              } catch (error) {
                  console.error(`Ошибка загрузки текстуры для символа ${symbol.id}:`, error);
                  if (mountedRef.current) {
                      textures[symbol.id] = fallbackTexture; // Используем заглушку
                  }
              }
          } else {
              // Если у символа нет изображения, используем текстуру-заглушку
              textures[symbol.id] = fallbackTexture;
          }
      }
  
      texturesRef.current = textures; // Сохраняем текстуры в ref
      return textures;
    };

    const init = async () => {
      if (!mountedRef.current || !appRef.current) return;
      
      // Создаем контейнер для текстур
      const texturesContainer = new PIXI.Container();
      texturesContainer.sortableChildren  = true;
      texturesContainer.zIndex = 30;
      texturesContainer.interactive = true;
      appRef.current.stage.addChild(texturesContainer);
      texturesContainerRef.current = texturesContainer;

      // Создаем контейнер для оверлеев
      const overlayContainer = new PIXI.Container();
      overlayContainer.sortableChildren  = true;
      overlayContainer.zIndex = 1000;
      overlayContainer.interactive = true;
      appRef.current.stage.addChild(overlayContainer);
      overlayContainerRef.current = overlayContainer;

      await loadBackground();
      const textures = await loadTextures();
      
      if (!mountedRef.current || !appRef.current) return;
      
      if (Object.keys(textures).length > 0) {
        createReels(textures);
      }

      // Обновляем текстуры после инициализации
      updateTextures(updateTextures);
    };

    init().catch(console.error);

    return () => {
      mountedRef.current = false;
      if (appRef.current) {
        appRef.current.destroy(true);
        appRef.current = null;
      }
    };
  }, [slotData, width, height]);
  useEffect(() => {
    // Функция для обновления состояния с размерами экрана
    const handleResize = () => {
      console.log('Мобильная версия', document.documentElement.clientWidth);
      if(document.documentElement.clientWidth <= 430) {
        setIsMobileMod(true);
        console.log('Мобильная версия');
        setWindowSize({
          width: 430,
          height: 700 ,
        });
      } else {
        setIsMobileMod(false);
        setWindowSize({
          width: Math.min(document.documentElement.clientWidth, 800),
          height: Math.min(document.documentElement.clientHeight, 600) ,
        });
      }
    };

    // Добавляем слушатель событий на изменение размера окна
    window.addEventListener('resize', handleResize);

    // Убираем слушатель при размонтировании компонента
    return () => {
        window.removeEventListener('resize', handleResize);
    };
}, []);
  useEffect(() => {
    if (slotData.visual.symbolsAreaSettings || slotData.visual.symbolsAreaSettingsMobile) {
      updateSymbolsAreaBorder();
    }
  }, [slotData.visual.symbolsAreaSettings, slotData.visual.symbolsAreaSettingsMobile]);

  useEffect(() => {
    updateTextures();
  }, [slotData.visual.textures, slotData.visual.texturesMobile, width, height]);
 

  
  return (
    <div style={{ position: 'relative'}}>
      <Box ref={containerRef} sx={{ position: 'relative', width: '100%', height: '100%', '@media (max-width: 768px)': { height: '100%', width: '100vw' } }}  />
      <SlotLoader message="Идет загрузка ресурсов, пожалуйста подождите..." image={slotData.info.icon} open={loaderOpen}/>
      <BettingPanel
        user={user}
        slotData={slotData}
        setBalance = {setBalance}
        balance={balance}
        onSpin={(type, bet) => handleEvent(type, bet)}
        disabled={false}
        onOpenDeposit={() => setDepositOpen(true)}
      />
      <DepositDialog
        open={depositOpen}
        onClose={() => setDepositOpen(false)}
        amount={depositAmount}
        setAmount={setDepositAmount}
        onDeposit={(amount) => {
          // Здесь будет логика пополнения
          setDepositOpen(false);
        }}
      />
    </div>
  );
});

export default SlotMachines;
