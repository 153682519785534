import { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layouts/layout";
import Home from "./pages/Home";
import Slot from "./pages/Slot";
import Bonuses from "./pages/Bonuses";
import Promotions from "./pages/Promotions";
import Deposit from "./pages/Deposit";
import Withdrawal from "./pages/Withdrawal";
import HistoryTransaction from "./pages/HistoryTransaction";
import Profile from "./pages/Profile";
import SlotsHistory from "./pages/SlotsHistory";
export default function App() {
  useEffect(() => {
    document.title = "CasicLove - Онлайн казино";
    
    const link = document.querySelector("link[rel~='icon']");
    if (link) {
       link.href = "/chip.png";
    }
  }, []);
  window.WebSocket = function () {
    console.log("WebSocket заблокирован!");
    return { close: () => {} };
  };
  return (
    <BrowserRouter>
      <Routes>
         <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/bonuses" element={<Bonuses />} />
          <Route path="/promotions" element={<Promotions />} />
          <Route path="/slot/:id" element={<Slot />} />
          <Route path="/deposit" element={<Deposit />} />
          <Route path="/withdraw" element={<Withdrawal />} />
          <Route path="/transaction/history" element={<HistoryTransaction />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/slots/history" element={<SlotsHistory />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
