import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';
import "../style/layout.css"
const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(184, 134, 11, 0.7);
  }
  
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(184, 134, 11, 0);
  }
  
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(184, 134, 11, 0);
  }
`;

const LoadingScreen = ({message}) => {
    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#111111',
                zIndex: 9999,
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    width: '80px',
                    height: '80px',
                }}
            >
                {/* Внешний спиннер */}
                <Box
                    sx={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        border: '4px solid transparent',
                        borderTopColor: '#B8860B',
                        borderRightColor: '#B8860B',
                        borderRadius: '50%',
                        animation: `${spin} 1.5s linear infinite`,
                    }}
                />
                {/* Внутренний спиннер */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: '15%',
                        left: '15%',
                        width: '70%',
                        height: '70%',
                        border: '4px solid transparent',
                        borderBottomColor: '#B8860B',
                        borderLeftColor: '#B8860B',
                        borderRadius: '50%',
                        animation: `${spin} 1.2s linear infinite reverse`,
                    }}
                />
                {/* Центральная точка */}
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        width: '12px',
                        height: '12px',
                        backgroundColor: '#B8860B',
                        borderRadius: '50%',
                        transform: 'translate(-50%, -50%)',
                        animation: `${pulse} 2s infinite`,
                    }}
                />
            </Box>
            <Box sx={{ mt: 2, color: 'var(--button-color)' }}>
            {message || "Загрузка..."}  {/* Показываем переданный текст или дефолтное сообщение */}
            </Box>
        </Box>
    );
};

export default LoadingScreen;
