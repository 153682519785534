import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Typography,
    Grid,
    Box,
    IconButton,
    Paper,
    Fade,
    Divider,
    Alert,
    CircularProgress
} from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { cryptoAPI, withdrawalApi } from '../../Api';
import { useAuth } from '../../context/AuthContext';
import { transactionApi } from '../../Api';

export const DepositDialog = ({ open, onClose, amount, setAmount, onDeposit }) => {
    const {userData } = useAuth();
    console.log('TEST USER DATA', userData)
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [copied, setCopied] = useState(false);
    const [rates, setRates] = useState({});
    const paymentMethods = [
        // { 
        //     id: "usdt_trc20", 
        //     icon: <Box 
        //         component="img"
        //         src="/images/payment-methods/usdt-trc20.png"
        //         sx={{ 
        //             width: 70,
        //             height: 70,
        //             objectFit: 'contain'
        //         }}
        //     />, 
        //     type: "crypto", 
        //     name: "USDT TRC20", 
        //     address: userData.user.wallets.usdt_trc20.address 
        // },
        // { 
        //     id: "usdt_erc20", 
        //     icon: <Box 
        //         component="img"
        //         src="/images/payment-methods/erc20.png"
        //         sx={{ 
        //             width: 70,
        //             height: 70,
        //             objectFit: 'contain'
        //         }}
        //     />, 
        //     type: "crypto", 
        //     name: "USDT ERC20", 
        //     address: userData.user.wallets.usdt_erc20.address 
        // },
        // { 
        //     id: "btc", 
        //     icon: <Box 
        //         component="img"
        //         src="/images/payment-methods/btc.png"
        //         sx={{ 
        //             width: 70,
        //             height: 70,
        //             objectFit: 'contain'
        //         }}
        //     />, 
        //     type: "crypto", 
        //     name: "Bitcoin", 
        //     address: userData.user.wallets.btc.address 
        // },
        // { 
        //     id: "eth", 
        //     icon: <Box 
        //         component="img"
        //         src="/images/payment-methods/eth.png"
        //         sx={{ 
        //             width: 70,
        //             height: 70,
        //             objectFit: 'contain'
        //         }}
        //     />, 
        //     type: "crypto", 
        //     name: "Ethereum", 
        //     address: userData.user.wallets.eth.address 
        // },
        { 
            id: "sbp", 
            icon: <Box 
                component="img"
                src="/images/payment-methods/sbp.png"
                sx={{ 
                    width: 70,
                    height: 70,
                    objectFit: 'contain'
                }}
            />, 
            type: "fiat", 
            name: "Перевод по СБП" 
        },
        { 
            id: "card", 
            icon: <Box 
                component="img"
                src="/images/payment-methods/visa_mastercard.png"
                sx={{ 
                    width: 70,
                    height: 70,
                    objectFit: 'contain'
                }}
            />, 
            type: "fiat", 
            name: "Перевод по карте" 
        }
    ];
    useEffect(() => {
        const fetchRates = async () => {
            const { data } = await cryptoAPI.getCurrency();
            console.log('dat',data.data)
            setRates(data.data);
        };
        fetchRates();
    }, []);
    const CallDeposit = async () => {
        console.log('Deposit',selectedMethod)
        console.log('DEP', amount)
        if(selectedMethod?.type === 'fiat') {
            if(amount > 0) {
                const transaction = await transactionApi.createFiatDeposit({
                    amount,
                    paymentMethod: selectedMethod.id
                })
                if(transaction.data.paymentDetails.link){
                    onDeposit(selectedMethod)
                    window.open(transaction.data.paymentDetails.link, '_blank');
                }
                console.log('Transaction', transaction)
            }
        }
    }
    const getCryptoCurs = (currency, amount) => {
        return (amount / rates[currency]).toFixed(8);
    };

    
    const handleCopyAddress = (address) => {
        navigator.clipboard.writeText(address);

    };

    const renderMethodGrid = () => (
        <Fade in={!selectedMethod}>
            <Grid 
                container 
                spacing={2} 
                sx={{ 
                    width: '100%',
                }}
            >
                {paymentMethods.map((method) => (
                    <Grid item xs={6} sm={4} key={method.id}>
                        <Paper
                            elevation={selectedMethod?.id === method.id ? 6 : 1}
                            sx={{
                                cursor: 'pointer',
                                p: 2,
                                height: '130px',
                                width: '130px',
                                mx: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                transition: 'all 0.2s ease-in-out',
                                '&:hover': {
                                    transform: 'translateY(-4px)',
                                    boxShadow: 6
                                }
                            }}
                            onClick={() => setSelectedMethod(method)}
                        >
                            {method.icon}
                            <Typography
                                variant="body1"
                                align="center"
                                sx={{ 
                                    fontWeight: 500,
                                    mt: 2
                                }}
                            >
                                {method.name}
                            </Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Fade>
    );

    const renderCryptoContent = () => (
        <Fade in={selectedMethod?.type === 'crypto'}>
            <Box sx={{ 
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 3
            }}>
                <Box>
                    <Typography variant="h6" gutterBottom>
                        Пополнение через {selectedMethod.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Отправьте любую сумму на указанный адрес
                    </Typography>
                </Box>
                
                <TextField
                    fullWidth
                    label="Сумма в рублях"
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    InputProps={{ inputProps: { min: 0 } }}
                />
                
                <Paper sx={{ p: 2, bgcolor: 'primary.light', color: 'primary.contrastText' }}>
                    <Typography variant="subtitle2" gutterBottom>
                        Сумма к получению:
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h6">
                            {getCryptoCurs(selectedMethod.id, amount)} {selectedMethod.name}
                        </Typography>
                        <IconButton 
                            onClick={() => handleCopyAddress(getCryptoCurs(selectedMethod.id, amount))}
                            color={copied ? "success" : "default"}
                        >
                            {copied ? <CheckCircleOutlineIcon /> : <ContentCopyIcon />}
                        </IconButton>
                    </Box>
                </Paper>

                <Box sx={{ 
                    display: { xs: 'none', sm: 'flex' },
                    justifyContent: 'center',
                    p: 3,
                    bgcolor: '#fff',
                    borderRadius: 1
                }}>
                    <QRCodeSVG value={selectedMethod.address} size={180} />
                </Box>

                <Paper variant="outlined">
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="caption" color="text.secondary" display="block" gutterBottom>
                                Адрес кошелька
                            </Typography>
                            <Typography variant="body2" sx={{ wordBreak: 'break-all' }}>
                                {selectedMethod.address}
                            </Typography>
                        </Box>
                        <IconButton 
                            onClick={() => handleCopyAddress(selectedMethod.address)}
                            color={copied ? "success" : "default"}
                        >
                            {copied ? <CheckCircleOutlineIcon /> : <ContentCopyIcon />}
                        </IconButton>
                    </Box>
                </Paper>

                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={onDeposit}
                 
                >
                   Я оплатил
                </Button>

                <Paper 
                    sx={{ 
                        p: 2,
                        bgcolor: 'warning.light',
                        color: 'warning.dark',
                        border: 1,
                        borderColor: 'warning.main'
                    }}
                >
                    <Typography variant="body2">
                        ⚠️ Важно: используйте только сеть {selectedMethod.name}. 
                        Использование другой сети может привести к потере средств.
                    </Typography>
                </Paper>
            </Box>
        </Fade>
    );

    const renderFiatContent = () => (
        <Fade in={selectedMethod?.type === 'fiat'}>
            <Box sx={{ mt: 2 }}>
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        Пополнение через {selectedMethod.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        Введите сумму пополнения (Мин. 250 руб.)
                    </Typography>
                </Box>

                <TextField
                    fullWidth
                    label="Сумма в рублях"
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    InputProps={{ inputProps: { min: 250 } }}
                    sx={{ mb: 3 }}
                />
                
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={CallDeposit}
                    disabled={!amount || amount < 250}
                >
                    Оплатить {amount} ₽
                </Button>
            </Box>
        </Fade>
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            fullScreen={window.innerWidth <= 600}
            PaperProps={{
                sx: {
                    minHeight: 'auto',
                    maxHeight: selectedMethod ? 'fit-content' : '80vh',
                    m: window.innerWidth <= 600 ? 0 : 2,
                    borderRadius: window.innerWidth <= 600 ? 0 : 1
                }
            }}
        >
            <DialogTitle sx={{ pb: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {selectedMethod && (
                        <IconButton
                            edge="start"
                            onClick={() => setSelectedMethod(null)}
                            sx={{ mr: 1 }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    )}
                    <Typography variant="h6">
                        {selectedMethod ? `Пополнение через ${selectedMethod.name}` : 'Пополнить баланс'}
                    </Typography>
                </Box>
            </DialogTitle>

            <DialogContent sx={{ 
                p: selectedMethod ? 3 : 2,
                height: selectedMethod ? 'auto' : '100%',
                overflowY: 'auto'
            }}>
                {!selectedMethod && renderMethodGrid()}
                {selectedMethod?.type === 'crypto' && renderCryptoContent()}
                {selectedMethod?.type === 'fiat' && renderFiatContent()}
            </DialogContent>
            
            <Divider />
            
            <DialogActions sx={{ p: 2 }}>
                <Button 
                    onClick={onClose}
                    variant="outlined"
                >
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const WithdrawDialog = ({ open, onClose, amount, setAmount, balance, moneyFormat }) => {
    const [destination, setDestination] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async () => {
        try {
            setLoading(true);
            setError(null);
            
            await withdrawalApi.create({
                amount: parseFloat(amount),
                destination
            });

            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
                onClose();
                setAmount('');
                setDestination('');
            }, 2000);
        } catch (error) {
            setError(error.response?.data?.error || 'Произошла ошибка');
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setError(null);
        setSuccess(false);
        setDestination('');
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                Вывод средств
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: 16,
                            marginTop: '-12px'
                        }}
                    />
                )}
            </DialogTitle>
            <DialogContent>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                {success && (
                    <Alert severity="success" sx={{ mb: 2 }}>
                        Заявка на вывод средств успешно создана
                    </Alert>
                )}
                <Typography variant="body2" sx={{ mb: 2 }}>
                    Доступно для вывода: {moneyFormat(balance)}
                </Typography>
                <TextField
                    fullWidth
                    label="Сумма вывода"
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    InputProps={{ inputProps: { min: 0, max: balance } }}
                    sx={{ mb: 2 }}
                />
                <TextField
                    fullWidth
                    label="Куда вывести (реквизиты)"
                    value={destination}
                    onChange={(e) => setDestination(e.target.value)}
                    multiline
                    rows={3}
                    placeholder="Укажите реквизиты для вывода средств (например, номер карты, кошелек и т.д.)"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Отмена</Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    variant="contained"
                    disabled={loading || !amount || !destination || parseFloat(amount) <= 0 || parseFloat(amount) > balance}
                >
                    Отправить заявку
                </Button>
            </DialogActions>
        </Dialog>
    );
};
