import React from 'react';
import { Box, Container, useTheme, useMediaQuery } from '@mui/material';
import { keyframes } from '@mui/system';

// Анимация для перемещения текста
const marquee = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const Marquee = ({ links }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const duplicatedLinks = [...links, ...links];

  return (
    <Box
      sx={{
        padding: isMobile ? '0 5%' : '0 15%', 
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: isMobile ? '5%' : '15%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          zIndex: 1
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          width: isMobile ? '5%' : '15%',
          height: '100%',
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          zIndex: 1
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          color: '#f8c24f',
          padding: isMobile ? '8px 0' : '10px 0',
          position: 'relative',
          height: isMobile ? '2.5vh' : '3vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            animation: `${marquee} ${isMobile ? '18s' : '28s'} linear infinite`,
            position: 'absolute',
            left: 0,
            '&:hover': {
              animationPlayState: 'paused'
            }
          }}
        >
          {duplicatedLinks.map((link, index) => (
            <Box 
              key={index} 
              sx={{ 
                marginRight: isMobile ? 2 : 4,
                display: 'inline-block'
              }}
            >
              <a
                href={link.url}
                target="_blank"
                style={{
                  color: 'red',
                  textDecoration: 'none',
                  padding: isMobile ? '0 5px' : '0 10px',
                  transition: 'color 0.3s',
                  textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                  fontSize: isMobile ? '0.85rem' : '1rem',
                  whiteSpace: 'nowrap',
                  fontWeight: 'bold',
                }}
              >
                {link.text}
              </a>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const App = () => {
  const links = [
    { text: 'За каждый найденый баг или ошибку - получите 💰500р на карту Сообщать: @casiclovesup', url: 'https://t.me/casiclovesup' },
    // { text: '💰 Получите бонус за регистрацию', url: '#' },
    // { text: '🎰 Широкий выбор игр', url: '#' },
    // { text: '🏆 Турниры каждый день', url: '#' },
    // { text: '💎 VIP программа', url: '#' },
    // { text: '🎁 Специальные предложения', url: '#' }
  ];

  return <Marquee links={links} />;
};

export default App;
