import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Box,
  IconButton,
  styled,
  Menu,
  MenuItem,
  Divider,
  Alert,
  Snackbar
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MenuIcon from '@mui/icons-material/Menu';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { userAPI } from '../../Api';
import { useAuth } from '../../context/AuthContext';

const StyledPanel = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 20,
  left: '50%',
  transform: 'translateX(-50%)',
  width: 'auto',
  minWidth: 300,
  padding: '8px 20px',
  background: 'rgba(0, 0, 0, 0.8)',
  borderRadius: '30px',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  display: 'flex',
  alignItems: 'center',
  gap: '20px',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
  zIndex: 1000,

  [theme.breakpoints.down('sm')]: {
    width: '80%',
    flexWrap: "wrap",
    justifyContent: "center",
    gap:"50%",
  },
}));

const BalanceBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  color: '#fff',
});

const BetControls = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
});

const StyledButton = styled(Button)({
  minWidth: 40,
  height: 40,
  padding: '0 15px',
  borderRadius: '20px',
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: '#fff',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
});

const MenuButton = styled(IconButton)({
  width: 40,
  height: 40,
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  color: '#fff',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
});

const SpinButton = styled(IconButton)({
  width: 60,
  height: 60,
  backgroundColor: '#e31837',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#ff1e40',
  },
  '&:disabled': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: 'rgba(255, 255, 255, 0.3)',
  },
});

const StyledMenuItem = styled(MenuItem)({
  color: '#fff',
  gap: '8px',
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
});

const StyledMenu = styled(Menu)({
  '& .MuiPaper-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    backdropFilter: 'blur(10px)',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    borderRadius: '15px',
    minWidth: '200px',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
  },
});

const BettingPanel = ({
  user,
  slotData,
  onSpin = () => {},
  setBalance,
  balance,
  disabled = false,
  onOpenDeposit = () => {}
}) => {
  const { userData, updateUserData } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentBet, setCurrentBet] = useState(slotData.gameSettings.minBet);
  const [showLowBalanceAlert, setShowLowBalanceAlert] = useState(false);
  const open = Boolean(anchorEl);
  const minBet = slotData.gameSettings.minBet;
  const maxBet = slotData.gameSettings.maxBet;

  useEffect(() => {
    onSpin('updateBet', currentBet);
  }, [currentBet, onSpin]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('ru-RU', {
      currency: 'RUB',
      style: 'currency',
      maximumFractionDigits: 2
    }).format(amount);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const getBets = () => {
    const bets = [];
    let bet = slotData.gameSettings.minBet;
    while (bet <= slotData.gameSettings.maxBet) {
      bets.push(bet);
      const nextBet = bet * 2;
      if (nextBet === bet || nextBet > slotData.gameSettings.maxBet) break;
      bet = nextBet;
    }
    if (!bets.includes(slotData.gameSettings.maxBet)) {
      bets.push(slotData.gameSettings.maxBet);
    }
    return bets;
  }
  const handleDecreaseBet = () => {
    const bets = getBets();
    const currentIndex = bets.indexOf(currentBet);
    if (currentIndex > 0) {
      setCurrentBet(bets[currentIndex - 1]);
    }
  };

  const handleIncreaseBet = () => {
    const bets = getBets();
    const currentIndex = bets.indexOf(currentBet);
    if (currentIndex < bets.length - 1) {
      setCurrentBet(bets[currentIndex + 1]);
    }
  };

  const handleSpin = async (action, bet) => {
    const response = await userAPI.getMyBalance();
    setBalance(response.data.balance)
    updateUserData({
      ...userData,
      user: {
        ...userData.user,
        balance: response.data.balance
      }
    })
    console.log('BALANCEEE ', response.data.balance)
    if (response.data.balance < bet) {
        setShowLowBalanceAlert(true);
        onOpenDeposit();
        return;
    }
    onSpin(action, bet);
  };

  return (
    <>
      <Snackbar 
        open={showLowBalanceAlert} 
        autoHideDuration={5000} 
        onClose={() => setShowLowBalanceAlert(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{
          position: 'fixed',
          top: { xs: '0px !important', sm: '24px !important' },
          left: '50% !important',
          right: 'auto !important',
          transform: 'translateX(-50%)',
          width: '100%',
          maxWidth: { xs: '100%', sm: '600px' },
          zIndex: 99999, 
          p: { xs: 1, sm: 2 }
        }}
      >
        <Alert 
          severity="warning" 
          variant="filled"
          onClose={() => setShowLowBalanceAlert(false)}
          sx={{
            width: '100%',
            borderRadius: { xs: 0, sm: 1 },
            boxShadow: 3, 
            '& .MuiAlert-message': {
              width: '100%',
              textAlign: 'center',
              fontSize: { xs: '0.875rem', sm: '1rem' },
              py: { xs: 0.5, sm: 1 }
            },
            '& .MuiAlert-icon': {
              fontSize: { xs: '20px', sm: '24px' }
            },
            '& .MuiAlert-action': {
              pt: { xs: 0, sm: 'initial' }
            }
          }}
        >
          Недостаточно средств на балансе, пополните свой счет чтобы продолжить
        </Alert>
      </Snackbar>

      <StyledPanel>
        <MenuButton
          onClick={handleMenuClick}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <MenuIcon />
        </MenuButton>

        <StyledMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <StyledMenuItem onClick={handleMenuClose}>
            <VolumeUpIcon />
            <Typography>Звук</Typography>
          </StyledMenuItem>
          <StyledMenuItem onClick={handleMenuClose}>
            <SettingsIcon />
            <Typography>Настройки</Typography>
          </StyledMenuItem>
          <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.1)' }} />
          <StyledMenuItem onClick={handleMenuClose}>
            <HelpOutlineIcon />
            <Typography>Помощь</Typography>
          </StyledMenuItem>
        </StyledMenu>

        <BalanceBox>
          <Typography variant="caption" sx={{ opacity: 0.7 }}>
            БАЛАНС
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {formatCurrency(balance)}
          </Typography>
        </BalanceBox>
        <BetControls>
          <StyledButton onClick={handleDecreaseBet} disabled={disabled}>
            <RemoveIcon />
          </StyledButton>
          <Typography variant="h6" sx={{ color: '#fff', minWidth: 80, textAlign: 'center' }}>
            {formatCurrency(currentBet)}
          </Typography>
          <StyledButton onClick={handleIncreaseBet} disabled={disabled}>
            <AddIcon />
          </StyledButton>
          <SpinButton
            onClick={() => handleSpin('spin', currentBet)}
            disabled={disabled}
          >
            <AutorenewIcon />
          </SpinButton>
        </BetControls>
      </StyledPanel>
    </>
  );
};

export default BettingPanel;