import React, { useState, useEffect } from 'react';
import {
    Container,
    Box,
    Typography,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Chip,
    Paper,
    Alert,
    Avatar,
    Grid,
    IconButton,
    Tooltip,
    Snackbar,
    CircularProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from '@mui/material';

import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import StarIcon from '@mui/icons-material/Star';
import TelegramIcon from '@mui/icons-material/Telegram';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { userAPI } from '../Api';
import '../layouts/style/profile.css';

const Profile = () => {
    const [profile, setProfile] = useState({
        email: '',
        phone_number: '',
        birthday: null,
        confirmed_email: false,
        confirmed_phone: false,
        tg_id: '',
        first_name: '',
        last_name: '',
        language_code: '',
        username: '',
        balance: 0,
        bonusBalance: 0,
        vipLevel: 0,
        vipPoints: 0,
        isVerified: false,
        createdAt: '',
        avatar: '',
        balanceVager: 0,
        totalDeposit: 0,
        totalWithdraw: 0,
        totalGames: 0,
        totalWin: 0,
        totalLose: 0
    });
    
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    
    const [openEmailInput, setOpenEmailInput] = useState(false);
    const [openPhoneInput, setOpenPhoneInput] = useState(false);
    const [openVerificationPopup, setOpenVerificationPopup] = useState(false);
    const [openBirthdayInput, setOpenBirthdayInput] = useState(false);
    
    const [newEmail, setNewEmail] = useState('');
    const [newPhone, setNewPhone] = useState('');
    const [newBirthday, setNewBirthday] = useState('');
    
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    useEffect(() => {
        loadProfile();
    }, []);

    const loadProfile = async () => {
        try {
            setLoading(true);
            const response = await userAPI.getProfileMe();
            setProfile(response.data);
        } catch (err) {
            setError('Ошибка загрузки профиля');
            showSnackbar('Ошибка при загрузке профиля', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleVerificationClick = () => {
        setOpenVerificationPopup(true);
    };

    const handleOpenBirthdayInput = () => {
        if (profile.birthday) {
            const date = new Date(profile.birthday);
            const formattedDate = date.toISOString().split('T')[0];
            setNewBirthday(formattedDate);
        }
        setOpenBirthdayInput(true);
    };

    const handleOpenPhoneInput = () => {
        if (profile.phone_number) {
            setNewPhone(profile.phone_number);
        }
        setOpenPhoneInput(true);
    };

    const handleEmailUpdate = async () => {
        try {
            await userAPI.updateProfile({ email: newEmail });
            setOpenEmailInput(false);
            setNewEmail('');
            await loadProfile();
            showSnackbar('Email успешно обновлен', 'success');
        } catch (err) {
            console.error('Error updating email:', err);
            if (err.response && err.response.data && err.response.data.message) {
                showSnackbar(err.response.data.message, 'error');
            } else {
                showSnackbar('Ошибка при обновлении email', 'error');
            }
        }
    };

    const handleBirthdayUpdate = async () => {
        try {
            await userAPI.updateProfile({ birthday: newBirthday });
            setOpenBirthdayInput(false);
            setNewBirthday('');
            await loadProfile();
            showSnackbar('Дата рождения успешно обновлена', 'success');
        } catch (err) {
            console.error('Error updating birthday:', err);
            if (err.response && err.response.data && err.response.data.message) {
                showSnackbar(err.response.data.message, 'error');
            } else {
                showSnackbar('Ошибка при обновлении даты рождения', 'error');
            }
        }
    };

    const handlePhoneUpdate = async () => {
        try {
            const cleanPhone = newPhone.replace(/[^\d+]/g, '');
            if (!/^\+?\d+$/.test(cleanPhone)) {
                showSnackbar('Неверный формат номера телефона', 'error');
                return;
            }
            await userAPI.updateProfile({ phone_number: cleanPhone });
            setOpenPhoneInput(false);
            setNewPhone('');
            await loadProfile();
            showSnackbar('Телефон успешно обновлен', 'success');
        } catch (err) {
            console.error('Error updating phone:', err);
            if (err.response && err.response.data && err.response.data.message) {
                showSnackbar(err.response.data.message, 'error');
            } else {
                showSnackbar('Ошибка при обновлении телефона', 'error');
            }
        }
    };

    const handleAvatarUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('avatar', file);

        try {
            await userAPI.updateAvatar(formData);
            await loadProfile();
            showSnackbar('Аватар успешно обновлен', 'success');
        } catch (err) {
            showSnackbar('Ошибка при обновлении аватара', 'error');
        }
    };

    const showSnackbar = (message, severity = 'success') => {
        setSnackbar({ open: true, message, severity });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    if (loading) {
        return (
            <Container maxWidth="md" className="profile-container">
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                    <Typography variant="h6" className="text-secondary" sx={{ color: 'var(--text-primary)' }}>
                        Загрузка профиля...
                    </Typography>
                </Box>
            </Container>
        );
    }

    return (
        <Container maxWidth="md" className="profile-container">
            <Paper className="styled-paper" elevation={0}>
                <Box className="profile-content">
                    <Box position="relative" display="inline-block">
                        <Avatar 
                            className="profile-avatar"
                            src={profile.avatar || '/default-avatar.png'}
                            alt={profile.username}
                        />
                    </Box>
                    <Typography variant="h4" className="profile-username">
                        {profile.username}
                    </Typography>
                    <Typography variant="body1" className="profile-date">
                        На платформе с {new Date(profile.createdAt).toLocaleDateString()}
                    </Typography>
                    {/* {!profile.isVerified && (
                        <Button
                            variant="contained"
                            onClick={handleVerificationClick}
                            startIcon={<VerifiedUserIcon />}
                            className="verification-button"
                        >
                            Верификация
                        </Button>
                    )} */}
                </Box>

                <Grid container spacing={3} className="grid-container">
                    {/* Финансовая информация */}
                    <Grid item xs={12} md={6} className="grid-item">
                        <Box className="info-card">
                            <Box className="section-header">
                                <AccountBalanceWalletIcon />
                                <Typography variant="h6">Финансы</Typography>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box className="stat-box">
                                        <Typography variant="subtitle2" className="text-darkgray subtitle-margin">
                                            Баланс
                                        </Typography>
                                        <Typography variant="body1" className="text-balance">
                                            {(profile.balance || 0).toFixed(2)} р.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box className="stat-box">
                                        <Typography variant="subtitle2" className="text-darkgray subtitle-margin">
                                            Бонусы
                                        </Typography>
                                        <Typography variant="body1" className="text-balance">
                                            {(profile.bonusBalance || 0).toFixed(2)} р.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box className="stat-box">
                                        <Typography variant="subtitle2" className="text-darkgray subtitle-margin">
                                            Всего депозитов
                                        </Typography>
                                        <Typography variant="body1">
                                            {(profile.totalDeposit || 0).toFixed(2)} р.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box className="stat-box">
                                        <Typography variant="subtitle2" className="text-darkgray subtitle-margin">
                                            Всего выведено
                                        </Typography>
                                        <Typography variant="body1">
                                            {(profile.totalWithdraw || 0).toFixed(2)} р.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    {/* Игровая статистика */}
                    <Grid item xs={12} md={6} className="grid-item">
                        <Box className="info-card">
                            <Box className="section-header">
                                <StarIcon />
                                <Typography variant="h6">Статистика игр</Typography>
                            </Box>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Box className="stat-box">
                                        <Typography variant="subtitle2" className="text-darkgray subtitle-margin">
                                            VIP уровень
                                        </Typography>
                                        <Typography variant="body1">
                                            Уровень {profile.vipLevel || 0}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box className="stat-box">
                                        <Typography variant="subtitle2" className="text-darkgray subtitle-margin">
                                            VIP очки
                                        </Typography>
                                        <Typography variant="body1">
                                            {profile.vipPoints || 0}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box className="stat-box">
                                        <Typography variant="subtitle2" className="text-darkgray subtitle-margin">
                                            Всего игр
                                        </Typography>
                                        <Typography variant="body1">
                                            {profile.game_count || 0}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box className="stat-box">
                                        <Typography variant="subtitle2" className="text-darkgray subtitle-margin">
                                            Вейджер
                                        </Typography>
                                        <Typography variant="body1">
                                            {(profile.balanceVager || 0).toFixed(2)} р.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box className="stat-box">
                                        <Typography variant="subtitle2" className="text-darkgray subtitle-margin">
                                            Общий выигрыш
                                        </Typography>
                                        <Typography variant="body1" className="text-success">
                                            {(profile.winAmount || 0).toFixed(2)} р.
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box className="stat-box">
                                        <Typography variant="subtitle2" className="text-darkgray subtitle-margin">
                                            Общий проигрыш
                                        </Typography>
                                        <Typography variant="body1" className="text-error">
                                            {(profile.luseAmount || 0).toFixed(2)} р.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    {/* Личная информация */}
                    <Grid item xs={12} className="grid-item">
                        <Box className="info-card">
                            <Box className="section-header">
                                <AccountBoxIcon />
                                <Typography variant="h6">Личная информация</Typography>
                            </Box>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Box className="stat-box">
                                        <Typography variant="subtitle2" className="text-darkgray subtitle-margin">
                                            Имя
                                        </Typography>
                                        <Typography variant="body1">
                                            {profile.first_name || 'Не указано'}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box className="stat-box">
                                        <Typography variant="subtitle2" className="text-darkgray subtitle-margin">
                                            Фамилия
                                        </Typography>
                                        <Typography variant="body1">
                                            {profile.last_name || 'Не указано'}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box className="stat-box" onClick={handleOpenBirthdayInput} style={{ cursor: 'pointer' }}>
                                        <Box className="flex-between">
                                            <Typography variant="subtitle2" className="text-darkgray">
                                                Дата рождения
                                            </Typography>
                                            <Button
                                                size="small"
                                                startIcon={profile.birthday ? <EditIcon /> : <AddIcon />}
                                                className="edit-button"
                                                onClick={handleOpenBirthdayInput}
                                            >
                                                {profile.birthday ? 'Изменить' : 'Указать'}
                                            </Button>
                                        </Box>
                                        <Box className="flex-gap">
                                            <Typography variant="body1">
                                                {profile.birthday ? new Date(profile.birthday).toLocaleDateString() : 'Не указано'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box className="stat-box">
                                        <Typography variant="subtitle2" className="text-darkgray subtitle-margin">
                                            Язык
                                        </Typography>
                                        <Typography variant="body1">
                                            {profile.language_code || 'Не указан'}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    {/* Контактная информация */}
                    <Grid item xs={12} className="grid-item">
                        <Box className="info-card">
                            <Box className="section-header">
                                <ContactMailIcon />
                                <Typography variant="h6">Контактная информация</Typography>
                            </Box>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Box className="stat-box">
                                        <Box className="flex-between">
                                            <Typography variant="subtitle2" className="text-darkgray">
                                                Email
                                            </Typography>
                                            <Button
                                                size="small"
                                                startIcon={profile.email ? <EditIcon /> : <AddIcon />}
                                                onClick={() => {
                                                    if (profile.email) setNewEmail(profile.email);
                                                    setOpenEmailInput(true);
                                                }}
                                                className="edit-button"
                                            >
                                                {profile.email ? 'Изменить' : 'Добавить'}
                                            </Button>
                                        </Box>
                                        <Box className="flex-gap">
                                            <EmailIcon className="icon-telegram" />
                                            <Typography variant="body1">
                                                {profile.email || 'Не указан'}
                                            </Typography>
                                            {profile.confirmed_email && (
                                                <Chip size="small" color="success" icon={<VerifiedUserIcon />} label="Подтвержден" />
                                            )}
                                        </Box>
                                    </Box>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <Box className="stat-box">
                                        <Box className="flex-between">
                                            <Typography variant="subtitle2" className="text-darkgray">
                                                Телефон
                                            </Typography>
                                            <Button
                                                size="small"
                                                startIcon={profile.phone_number ? <EditIcon /> : <AddIcon />}
                                                onClick={handleOpenPhoneInput}
                                                className="edit-button"
                                            >
                                                {profile.phone_number ? 'Изменить' : 'Добавить'}
                                            </Button>
                                        </Box>
                                        <Box className="flex-gap">
                                            <PhoneIcon className="icon-telegram" />
                                            <Typography variant="body1">
                                                {profile.phone_number || 'Не указан'}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>

                                {profile.tg_id && (
                                    <Grid item xs={12} sm={6}>
                                        <Box className="stat-box">
                                            <Typography variant="subtitle2" className="text-darkgray subtitle-margin">
                                                Telegram
                                            </Typography>
                                            <Box className="flex-gap">
                                                <TelegramIcon className="icon-telegram" />
                                                <Typography variant="body1">
                                                    {(profile.username == "No username" ? profile.tg_id : profile.username) }
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>

                {/* Диалоги */}
                <Dialog open={openEmailInput} onClose={() => setOpenEmailInput(false)}>
                    <DialogTitle>Изменение Email</DialogTitle>
                    <DialogContent>
                        <Typography variant="body2" sx={{ mb: 2 }}>
                            Текущий email: {profile.email || 'Не указан'}
                        </Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Новый Email"
                            type="email"
                            fullWidth
                            variant="outlined"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenEmailInput(false)}>Отмена</Button>
                        <Button onClick={handleEmailUpdate} variant="contained">
                            Сохранить
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openPhoneInput} onClose={() => setOpenPhoneInput(false)}>
                    <DialogTitle>{profile.phone_number ? 'Изменение телефона' : 'Добавление телефона'}</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Телефон"
                            type="tel"
                            fullWidth
                            variant="outlined"
                            value={newPhone}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value === '' || /^\+?\d*$/.test(value)) {
                                    setNewPhone(value);
                                }
                            }}
                            placeholder="+7XXXXXXXXXX"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenPhoneInput(false)}>Отмена</Button>
                        <Button onClick={handlePhoneUpdate} variant="contained">
                            Сохранить
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog 
                    open={openVerificationPopup} 
                    onClose={() => setOpenVerificationPopup(false)}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>
                        <Box display="flex" alignItems="center" gap={1}>
                            <VerifiedUserIcon color="primary" />
                            Верификация профиля
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Typography color="textPrimary" sx={{ mb: 2 }}>
                            Для верификации профиля необходимо:
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <EmailIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Подтвердить email адрес" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <PhoneIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Подтвердить номер телефона" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <FileUploadIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Загрузить документы, удостоверяющие личность" />
                            </ListItem>
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenVerificationPopup(false)}>
                            Закрыть
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openBirthdayInput} onClose={() => setOpenBirthdayInput(false)}>
                    <DialogTitle>
                        <Box display="flex" alignItems="center" gap={1}>
                            <DateRangeIcon color="primary" />
                            Дата рождения
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            type="date"
                            value={newBirthday}
                            onChange={(e) => setNewBirthday(e.target.value)}
                            sx={{ mt: 2 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenBirthdayInput(false)}>Отмена</Button>
                        <Button onClick={handleBirthdayUpdate} variant="contained" color="primary">
                            Сохранить
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert 
                        onClose={handleCloseSnackbar} 
                        severity={snackbar.severity}
                        sx={{ width: '100%' }}
                    >
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            </Paper>
        </Container>
    );
};

export default Profile;
