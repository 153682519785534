import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import "./style/layout.css";
import { Box, Typography, Container } from "@mui/material";
import Menu from "./components/Menu";  // Импортируем компонент меню
import Auth from './components/Auth';
import { ModalProvider } from "../context/ModalContext";
import { AuthProvider } from "../context/AuthContext";
import StepLine from "./components/StepLine";
import Footer from "./components/Footer";
export default function Layout() {

  return (
    <AuthProvider>
    <ModalProvider>
    <Box >
      {/* Меню будет показываться на всех страницах */}
      <Menu/>
      <Auth/>
      <StepLine/>
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '88vh' }}>
      <Container fixed sx={{ mt: 3, backgroundColor: 'rgba(255, 255, 255, 0) !important' }}>

      {/* Контент, который будет меняться на разных страницах */}
      <div>
        <Outlet />
      </div>
      </Container>
     
      <Footer/>
    </Box>
    </Box>
    </ModalProvider>
    </AuthProvider>
  );
}
