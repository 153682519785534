import React from 'react';
import { 
  Box 
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

const Promotions = () => {


  return (
   <Box sx={{ color: "white" }}>
    Тут будет страница Акции
   </Box>
  );
};

export default Promotions;
