import React, { createContext, useState, useContext } from 'react';

// Создаем контекст для модалки
const ModalContext = createContext();

// Создаем Provider, который будет хранить состояние модалки и передавать его в компоненты
export const ModalProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  // Функции для открытия и закрытия модалки
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <ModalContext.Provider value={{ open, handleOpen, handleClose }}>
      {children}
    </ModalContext.Provider>
  );
};

// Хук для использования контекста
export const useModal = () => useContext(ModalContext);
