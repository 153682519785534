import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Button, Typography, useTheme, useMediaQuery, IconButton, Zoom } from '@mui/material';
import { slotAPI } from '../../../Api';
import { useAuth } from '../../../context/AuthContext';
import { useModal } from '../../../context/ModalContext';
import CasinoIcon from '@mui/icons-material/Casino';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import GridViewIcon from '@mui/icons-material/GridView';
import StarIcon from '@mui/icons-material/Star';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import "../../../layouts/style/layout.css";

const New = () => {
  const { open, handleOpen } = useModal();
  const { isAuthenticated } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [slots, setSlots] = useState([]);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('slots');

  useEffect(() => {
    const fetchSlots = async () => {
      try {
        const res = await slotAPI.getSlots();
        if (res.data && Array.isArray(res.data)) {
          const sortedSlots = res.data.sort((a, b) => {
            if (a.isPinned === b.isPinned) return 0;
            return a.isPinned ? -1 : 1;
          });
          setSlots(sortedSlots);
        }
      } catch (error) {
        console.error('Ошибка при загрузке слотов:', error);
      }
    };
    fetchSlots();
  }, []);

  const playSlot = (slotId) => {
    if(isAuthenticated) {
      navigate(`/slot/${slotId}`)
    } else {
      handleOpen()
    }
  };

  const categories = [
    { id: 'slots', name: 'Слоты', icon: <CasinoIcon /> },
    { id: 'roulette', name: 'Рулетка', icon: <SportsEsportsIcon /> },
    { id: 'rockets', name: 'Ракетки', icon: <RocketLaunchIcon /> },
    { id: 'mines', name: 'Мины', icon: <GridViewIcon /> },
  ];

  const renderContent = () => {
    switch (activeTab) {
      case 'slots':
        return (
          <Grid container spacing={{ xs: 1, sm: 2, md: 3 }}>
            {Array.isArray(slots) && slots.map((slot, index) => (
              <Grid item xs={6} sm={6} md={4} lg={3} key={slot.id}>
                <Zoom in={true} style={{ transitionDelay: `${index * 50}ms` }}>
                  <Box
                    sx={{
                      position: 'relative',
                      backgroundColor: 'rgba(30, 35, 45, 0.6)',
                      overflow: 'hidden',
                      height: '180px',
                      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                      backdropFilter: 'blur(10px)',
                      border: slot.isHighlighted ? `2px solid ${slot.highlightColor}` : '1px solid rgba(255, 255, 255, 0.1)',
                      borderRadius: slot.isHighlighted ? '8px' : '0px',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        boxShadow: slot.isHighlighted 
                          ? `0 12px 25px -8px ${slot.highlightColor}40` 
                          : '0 12px 25px -8px rgba(0, 0, 0, 0.5)',
                        '& .overlay': {
                          opacity: 1,
                          visibility: 'visible',
                        },
                        '& .play-button': {
                          opacity: 1,
                          transform: 'translateX(-50%) translateY(0)',
                        }
                      }
                    }}
                  >
                    <img
                      src={slot.icon}
                      alt={slot.name}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                    <Box
                      className="overlay"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.85) 100%)',
                        opacity: 0,
                        visibility: 'hidden',
                        transition: 'all 0.3s ease',
                        pointerEvents: 'none',
                      }}
                    />
                    <Typography
                      variant="h6"
                      className="play-button"
                      title={slot.name}
                      sx={{
                        color: 'white',
                        fontWeight: '500',
                        width: '-webkit-fill-available',
                        mb: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: { xs: '14px', sm: '16px', md: '18px' },
                        position: 'absolute',
                        top: '5px',
                        left: '50%',
                        textAlign: 'center',
                        width: '100%',
                        transform: 'translateX(-50%) translateY(20px)',
                        zIndex: 2,
                        opacity: 0,
                        transition: 'all 0.3s ease',
                      }}
                    >
                      {slot.name}
                    </Typography>
                    <Button
                      className="play-button"
                      variant="contained"
                      disableRipple
                      onClick={() => playSlot(slot.id)}
                      sx={{
                        position: 'absolute',
                        bottom: '20px',
                        left: '50%',
                        transform: 'translateX(-50%) translateY(20px)',
                        padding: '10px 30px',
                        opacity: 0,
                        zIndex: 2,
                        transition: 'all 0.3s ease',
                        border: `1px solid ${theme.palette.primary.main}`,
                        backgroundColor: 'transparent',
                        '&:hover': {
                          bgcolor: 'primary.dark'
                        }
                      }}
                    >
                      <PlayArrowIcon/>
                    </Button>
                  </Box>
                </Zoom>
              </Grid>
            ))}
          </Grid>
        );
      case 'roulette':
        return (
          <Box sx={{ 
            textAlign: 'center', 
            py: 8,
            background: 'rgba(30, 35, 45, 0.6)',
            borderRadius: '16px',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            p: 4
          }}>
            <Typography variant="h4" sx={{ color: 'var(--main-color)', mb: 2 }}>
              Рулетка
            </Typography>
            <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              Скоро будет доступно
            </Typography>
          </Box>
        );
      case 'rockets':
        return (
          <Box sx={{ 
            textAlign: 'center', 
            py: 8,
            background: 'rgba(30, 35, 45, 0.6)',
            borderRadius: '16px',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            p: 4
          }}>
            <Typography variant="h4" sx={{ color: 'var(--main-color)', mb: 2 }}>
              Ракетки
            </Typography>
            <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              Скоро будет доступно
            </Typography>
          </Box>
        );
      case 'mines':
        return (
          <Box sx={{ 
            textAlign: 'center', 
            py: 8,
            background: 'rgba(30, 35, 45, 0.6)',
            borderRadius: '16px',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            p: 4
          }}>
            <Typography variant="h4" sx={{ color: 'var(--main-color)', mb: 2 }}>
              Мины
            </Typography>
            <Typography variant="body1" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
              Скоро будет доступно
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }} className="menuBackGround">
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          marginBottom: '24px',
          overflowX: 'auto',
          paddingBottom: '8px',
          '&::-webkit-scrollbar': {
            height: '4px',
          },
          '&::-webkit-scrollbar-track': {
            background: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'var(--button-color)',
            borderRadius: '4px',
          },
          [theme.breakpoints.down('sm')]: {
            gap: '8px',
            marginBottom: '16px',
          }
        }}
      >
        {categories.map((category) => (
          <Button
            key={category.id}
            sx={{
              minWidth: '120px',
              padding: '8px 16px',
              borderRadius: '12px',
              color: 'white',
              backgroundColor: activeTab === category.id ? 'var(--button-color)' : 'rgba(255, 255, 255, 0.1)',
              transition: 'all 0.3s ease',
              textTransform: 'none',
              position: 'relative',
              '&:hover': {
                backgroundColor: activeTab === category.id ? 'var(--hover-color)' : 'rgba(255, 255, 255, 0.2)',
              },
              [theme.breakpoints.down('sm')]: {
                minWidth: '100px',
                padding: '6px 12px',
                fontSize: '14px',
              }
            }}
            startIcon={category.icon}
            onClick={() => setActiveTab(category.id)}
          >
            {category.name}
          </Button>
        ))}
      </Box>
      {renderContent()}
    </Box>
  );
};

export default New;
