import React from 'react';
import { 
  Box 
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

const HistoryTransaction = () => {


  return (
   <Box sx={{ color: "white" }}>
    Тут будет страница История транзакции
   </Box>
  );
};

export default HistoryTransaction;
