import React from 'react';
import { 
  Box 
} from '@mui/material';

import { useNavigate } from 'react-router-dom';

const Withdrawal = () => {


  return (
   <Box sx={{ color: "white" }}> 
    Тут будет страница Вывод средств
   </Box>
  );
};

export default Withdrawal;
