import React, { useState, useEffect } from 'react';
import { useAuth  } from '../../context/AuthContext';
import { QRCodeCanvas  } from "qrcode.react"; // Изменяем импорт
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ButtonOrange from '../elements/ButtonOrange';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputCastum from '../elements/InputCastum';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TelegramIcon from '@mui/icons-material/Telegram';
import { authAPI } from '../../Api';
import { isMobile } from 'react-device-detect';
import { useModal } from '../../context/ModalContext'; // Используем хук для доступа к контексту
import { alpha, styled } from '@mui/material/styles';
import "../style/auth.css"
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'white',
  border: '0',
  borderRadius: '15px',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Auth = () => {
  const { open, handleClose } = useModal(); // Доступ к состоянию модалки и функции закрытия
  const [value, setValue] = React.useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [tokenAUTH, setTokenAUTH] = useState('');
  const [tokenLink, setTokenLink] = useState('');
  const { login } = useAuth(); // Извлекаем функцию login из контекста
  console.log(useAuth())
  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
      setLoading(false);
      setValue(0);
    };
  }, [intervalId]);

  useEffect(() => {
    if (!open) {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
      setLoading(false);
      setValue(0);
    }
  }, [open, intervalId]);
  useEffect(() => {
    if (open) {
      const newToken = generateToken();
      setTokenAUTH(newToken);
      if(isMobile) {
        setTokenLink(`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT}?startapp`);
      } else {
        setTokenLink(`https://t.me/${process.env.REACT_APP_TELEGRAM_BOT}?start=auth_${newToken}`);
      }
      
      localStorage.setItem('AuthTelegramToken', newToken);

      const fetchAuthStatus = async () => {
        
        try {
          const response = await authAPI.checkTgAuth(newToken); 
          const data = response.data;
          console.log('Data:', {data:data, token:newToken});
          
          if(data.status === "false") return;
          if(data.token) {
            clearInterval(intervalId);
            setIntervalId(null);
            login(data.token, data.user);
            setLoading(false);
            handleClose();
          }
        } catch (error) {
          console.error("Ошибка запроса:", error);
          setLoading(false);
        }
      };
if(!isMobile) {
      const interval = setInterval(fetchAuthStatus, 3000);
      setIntervalId(interval);
      fetchAuthStatus();
}
    }
  }, [open, login, handleClose]);
  const generateToken = () => {
    const timestamp = Date.now();
    const array = new Uint32Array(6);
    window.crypto.getRandomValues(array);
    array[0] = timestamp;
    return Array.from(array).map((value) => value.toString(16).padStart(8, '0')).join('');
  };

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-flexContainer': {
     
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: 'var(--button-color)',
      
    },
  });
  
  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      color: 'var(--button-hover-color)',
  
      '&.Mui-selected': {
        color: 'var(--button-color)',
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
      },
    }),
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [loading, setLoading] = useState(false);
  const AuthTelegram = () => {
    setLoading(true);
    window.open(tokenLink, '_blank');
  }
 



  const SubmitForm = async (e) => {
    e.preventDefault();

    try {
      const formData = {};
      for (let element of e.target.elements) {
        if (element.name) {
          formData[element.name] = element.value;
        }
      }
      const response = await authAPI.login(formData.auth_code);
      console.log(response.data)
      const { token, user } = response.data;


      login(token, user);


    } catch (err) {
      console.error('Ошибка авторизации:', err);
    }
  };

  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
  >
    <Box sx={{ ...style, width: '100%', maxWidth: 400, '@media (max-width: 600px)': { maxWidth: 300 } }}>
      <h2 className="h2AuthTitle">Вход / Регистрация</h2>
    <Box
        component="form"
        className="auth-form-container"
        sx={{
          '& > :not(style)': { mt: 1, width: '100%' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}
        noValidate
        autoComplete="off"
        onSubmit={SubmitForm}
      >
         {!isMobile ? (
        <Box 
          className="qr-container"
          sx={{ 
            backgroundColor: '#fff',
            padding: '30px',
            borderRadius: '12px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
            mb: 3,
            position: 'relative'
          }}
        >
          <QRCodeCanvas
            value={tokenLink}
            size={200}
            level="H"
            style={{
              borderRadius: '8px'
            }}
          />
          <Typography 
            variant="subtitle1" 
            sx={{ 
              mt: 2,
              color: 'var(--button-color)',
              fontWeight: 500
            }}
          >
            Отсканируйте QR-код
          </Typography>
        </Box>
         ) : null}
         {!isMobile ? (
        <Typography 
          variant="body1" 
          sx={{ 
            mb: 2,
            color: 'rgba(0, 0, 0, 0.7)',
            maxWidth: '90%',
            fontSize: '0.95rem',
            lineHeight: 1.5
          }}
        >
          Для входа или регистрации в системе отсканируйте QR-код или нажмите кнопку ниже для открытия Telegram
        </Typography>
         ) : (        <Typography 
          variant="body1" 
          sx={{ 
            mb: 2,
            color: 'rgba(0, 0, 0, 0.7)',
            maxWidth: '90%',
            fontSize: '0.95rem',
            lineHeight: 1.5
          }}
        >
         Для использования сервиса на мобильных устройствах необходимо перейти в наше Мини-приложение в Telegram
        </Typography>)}
        {isMobile ? (
        <Button 
          fullWidth 
          variant="contained" 
          endIcon={loading ? (
            <CircularProgress 
              size={20} 
              sx={{ color: 'var(--button-color)' }}
            />
          ) : <TelegramIcon />}
          onClick={AuthTelegram}
          disabled={loading}
          sx={{ 
            backgroundColor: 'unset', 
            color: 'var(--button-color)', 
            border: 'solid 1px var(--button-color)', 
            boxShadow: 'unset',
            transition: 'all 0.3s ease',
            height: '48px',
            '&:hover': { 
              borderColor: 'var(--button-hover-color)', 
              color: 'var(--button-hover-color)', 
              boxShadow: 'unset',
              transform: 'translateY(-2px)'
            },
            '&.Mui-disabled': {
              color: 'var(--button-color)',
              borderColor: 'var(--button-color)',
              opacity: 0.7
            }
          }}
        >
          Открыть MiniApp Telegram
        </Button>
        ) : (   <Button 
          fullWidth 
          variant="contained" 
          endIcon={loading ? (
            <CircularProgress 
              size={20} 
              sx={{ color: 'var(--button-color)' }}
            />
          ) : <TelegramIcon />}
          onClick={AuthTelegram}
          disabled={loading}
          sx={{ 
            backgroundColor: 'unset', 
            color: 'var(--button-color)', 
            border: 'solid 1px var(--button-color)', 
            boxShadow: 'unset',
            transition: 'all 0.3s ease',
            height: '48px',
            '&:hover': { 
              borderColor: 'var(--button-hover-color)', 
              color: 'var(--button-hover-color)', 
              boxShadow: 'unset',
              transform: 'translateY(-2px)'
            },
            '&.Mui-disabled': {
              color: 'var(--button-color)',
              borderColor: 'var(--button-color)',
              opacity: 0.7
            }
          }}
        >
          Войти через Telegram
        </Button>)}

        {loading && (
          <Box 
            sx={{
              mt: 3,
              p: 3,
              backgroundColor: 'rgba(240, 150, 14, 0.05)',
              borderRadius: '12px',
              border: '1px solid rgba(240, 150, 14, 0.1)',
              width: '100%',
              animation: 'fadeIn 0.3s ease-out'
            }}
          >
            <Typography 
              variant="body2" 
              sx={{ 
                mb: 2, 
                color: 'rgba(0, 0, 0, 0.6)',
                textAlign: 'left'
              }}
            >
              Вход в систему происходит автоматически. Если возникли проблемы, введите код авторизации:
            </Typography>
            <InputCastum 
              variant="filled" 
              label="Код авторизации" 
              name="auth_code"
            />
            <ButtonOrange 
              fullWidth 
              variant="contained" 
              type="submit"
              sx={{ 
                mt: 2,
                height: '48px'
              }}
            >
              Войти
            </ButtonOrange>
          </Box>
        )}
      </Box>
    </Box>

    </Modal>
  );
};

export default Auth;
