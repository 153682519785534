import React, { useRef, useState, useEffect } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import { slotAPI } from '../Api';
import { useModal } from '../context/ModalContext'; // Используем хук для доступа к контексту
import SlotMachines from '../pages/component/SlotMachines';
const GameContainer = styled(Box)(({
  width: '100%',
  height: '100%',
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  alignItems: 'center',

  '& canvas': {
    width: '100%',
    height: '100%', // Можно указать высоту, чтобы сохранялась пропорциональность
  },
}));
const SlotGame = () => {

  const { id } = useParams();
  const { isAuthenticated, userData, logout, updateUserData} = useAuth();
  const [slotData, setSlotData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const slotMachineRef = useRef();
  
  const { handleOpen, handleClose } = useModal();

  useEffect(() => {

    const fetchSlotData = async () => {
      try {
        setLoading(true);
        setError(null);
        console.log('Fetching slot data for id:', id);
        const response = await slotAPI.getSlot(id);
        console.log('Received response:', response);
        
        if (!response || !response.data) {
          throw new Error('Invalid response format');
        }
        
        setSlotData(response.data);
      } catch (error) {
        console.error('Error fetching slot data:', error);
        setError(error.message || 'Failed to load slot data');
      } finally {
        setLoading(false);
      }
    };

    fetchSlotData();

  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!slotData) {
    return <div>No slot data available</div>;
  }

  console.log('USERDATAAAAAAAA:', localStorage.getItem('userData'));

  return (
    <GameContainer>
      <SlotMachines
        ref={slotMachineRef}
        id={id}
        slotData={slotData}
        user={userData.user}
      />
    </GameContainer>
  );
};

export default SlotGame;