import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import '../style/layout.css'
const InputCastum = styled((props) => (
  <TextField
    slotProps={{
      input: { disableUnderline: true },
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    margin: '0',
    borderRadius: 4,
    backgroundColor: 'var(--input-bg-color2)',
   
    '&:hover': {
      backgroundColor: 'var(--input-bg-color2)',
    },
    '&.Mui-focused': {
     backgroundColor: 'var(--input-bg-color2)',
      borderColor: "var(--button-color)",
   
    }
  },
  '& .MuiInputBase-input': {
    color: 'var(--hover-color)', // Цвет текста в поле
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: 'var(--button-color)', // Цвет метки (лейбла)
  },
  '& .css-z4k887>:not(style)': {
    margin: '0 !important'
  },

}));



export default InputCastum