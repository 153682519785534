import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';


const ButtonOrange = styled(Button)(({ theme }) => ({
  color: 'var(--hover-color)',
  textTransform: 'unset',
  fontWeight: 550,
  boxShadow: 'unset',
  borderRadius: '10px',
  backgroundColor: 'var(--button-color)',
  '&:hover': {
    boxShadow: 'unset',
    backgroundColor: 'var(--button-hover-color)',
  },
}));

export default ButtonOrange;