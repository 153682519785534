import { useState, useEffect } from "react";
import { 
    Box, Container, Typography, Table, TableBody, TableCell, TableContainer, 
    TableHead, TableRow, Paper, TablePagination, useMediaQuery, useTheme 
} from "@mui/material";
import { slotAPI } from "../Api";

const SlotsHistory = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [gameHistory, setGameHistory] = useState([]);
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));  // Проверка на мобильный экран

    const fetchGameHistory = async () => {
        try {
            const response = await slotAPI.getSlotHistory({ page: page });
            setGameHistory(response.data.data);
        } catch (error) {
            console.error('Error fetching game history:', error);
        }
    };

    useEffect(() => {
        fetchGameHistory();
    }, [page]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 50));
        setPage(0);
    };
    const formatDate = (dateString) => {
      const date = new Date(dateString);
  
      // Получаем день, месяц и год
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяц начинается с 0
      const year = String(date.getFullYear()).slice(-2); // Последние 2 цифры года
  
      // Формируем строку в нужном формате
      return `${day}.${month}.${year} 00:00:00`;
  };
    return (
        <Box sx={{ color: "white", p: 3 }}>
            <Container maxWidth="md">
                <Typography variant="h4" align="center" gutterBottom>
                    История игр
                </Typography>
                <TableContainer component={Paper} sx={{ backgroundColor: "rgba(255, 255, 255, 0.05)", color: "white" }}>
                    <Box sx={{ overflowX: 'auto' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: "white" }}>#</TableCell>
                                    {!isMobile && <TableCell sx={{ color: "white" }}>Логотип</TableCell>}
                                    <TableCell sx={{ color: "white" }}>Игра</TableCell>
                                    <TableCell sx={{ color: "white" }}>Ставка</TableCell>
                                    <TableCell sx={{ color: "white" }}>Выигрыш</TableCell>
                                    <TableCell sx={{ color: "white" }}>Дата</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {gameHistory.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell sx={{ color: "white" }}>{page * rowsPerPage + index + 1}</TableCell>
                                        {!isMobile && (
                                            <TableCell sx={{ color: "white" }}>
                                                <img src={row.slotId.info.icon} alt="logo" width="50px" height="50px"/>
                                            </TableCell>
                                        )}
                                        <TableCell sx={{ color: "white" }}>{row.slotId.info.name}</TableCell>
                                        <TableCell sx={{ color: "white" }}>{row.betAmount.toFixed(2)} р.</TableCell>
                                        <TableCell sx={{ color: row.win ? "green" : "red" }}>
                                            {row.winAmount.toFixed(2)} р.
                                        </TableCell>
                                        <TableCell sx={{ color: "white" }}>{formatDate(row.createdAt)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={gameHistory.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handleChangePage}
                    sx={{ color: "white", mt: 2 }}
                    rowsPerPageOptions={[]}
                    labelRowsPerPage=""
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
                />
            </Container>
        </Box>
    );
};

export default SlotsHistory;
