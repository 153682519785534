import React from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardMedia, 
  Button,
  Box 
} from '@mui/material';
import New from './component/Home/New';

import { useNavigate } from 'react-router-dom';

const Home = () => {


  return (
   <Box>
     <New />
   </Box>
  );
};

export default Home;
